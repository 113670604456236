import React, { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonWithIcon,
  Icon,
  Input,
  Label,
  LineReducer,
  LineSetContext,
  LineUI,
  SelectField,
  SmallInput,
  Spinner,
  Switch,
  useMediaModal,
  useModal,
  useNotification,
  usePoll,
  useClickOutside
} from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import { Divider } from 'Styles';
import ImportSettingsModal from './ImportModal';
import { IPoints, ITrafficCounterData, ITrafficCounterDataImport } from '../../interface';
import { useWebsocketFrame } from 'hooks/useWebsocketFrame';
import axios from 'axios';
import { isEqual, cloneDeep } from 'lodash';
import { EDGE_API_BASE_URL, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL } from './../../constants';
import i18n from 'i18n';
import AwaitingStreamEn from '../../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../../svg/img_awaiting_stream_jp.jpg';
import { ICameraDetails } from '.';
import BoxModal from 'components/BoxModal';
import TokenService from 'services/tokenService';

const SettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 9px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputAndButtonContainer = styled.div<{gap: string}>`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: ${({gap}) => gap};
  & > button {
    background: #e4edf4;
    :hover:enabled {
      background: #d4e4f0;
    }
    :disabled {
      background: #e9f0f6;
    }
  }
`;

const PeriodicAndCameraEnabled = styled.div`
  display: flex;
  align-items: center;
  gap: 0 39px;
  margin-right: 10px;
`;

const SwitchContainer = styled.div`
  width: 110px;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
`;

const DividerWithMargin = styled(Divider)`
  margin: 14px 0 12px;
`;

const SidePaddingDiv = styled.div`
  position: relative;
  padding: 0 20px;
`;

const DirectionSelectFieldContainer = styled.div`
  > div > label {
    margin-bottom: 10px;
  }
`;
const DirectionSelectField = styled(SelectField)`
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
`;

const DirectionInputField = styled.div`
  position: relative;
  & > div {
    margin-bottom: 10px;
  }
`;

const Circle = styled.div<{ size: number }>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%) scale(${({ size }) => size});
  background: hsla(203, 100%, 35%, 0.49);
  & > div {
    display: flex;
    margin-left: 0.4px;
  }
`;

const LabelWithoutMargin = styled(Label)`
  margin-bottom: 0;
  & > span {
    margin-bottom: 0;
  }
`;

const StyledSmallInputWithLabel = styled(SmallInput) <ISmallInput>`
  & > label {
    width: ${({ length }) => length};
    margin-bottom: 0;
  }
`;

const StyledInput = styled(Input) <ISmallInput>`
  width: ${({ length }) => length};
  min-height: 30px;
  padding-left: 10px;
  padding-right: 30px;
  font-size: 14px;
`;

const StyledSmallInput = styled(SmallInput) <ISmallInput>`
  width: ${({ length }) => length};
  min-height: 30px;
  & > label {
    margin-bottom: 0;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const ColoredButton = styled(Button)`
  width: 100%;
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 229px;
  background: #fff;
  &:disabled {
    opacity: 0.7;
  }
  & + div {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
`;

const SnapshotSetting = styled.div`
  display: none ! important;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  & > div {
    & > label {
      margin-bottom: 0;
      & > span {
        margin-bottom: 0;
      }
    }
  }
`;

const LineSelectFieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 10px;
  position: relative;
  & > div:first-child {
    position: absolute;
    top: 6px;
    left: -24px;
  }
`;

const LineSettingsContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  margin: 20px 0;
`;

const LineUILeftPanel = styled.div`
  padding: 15px 0;
  width: 240px;
  flex-shrink: 0;
  position: relative;
`;

const LineUIImageWrapper = styled.div<{ isReadOnly: boolean }>`
  width: 100%;
  height: 390px;
  position: relative;
  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    > img {
      width: max-content;
      box-shadow: -2px 0 0 0 #ddd, 2px 0 0 0 #ddd;
    }
  }
  ${({ isReadOnly }) => isReadOnly && css`
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  `}
`;

const LineUIRightPanel = styled.div`
  width: 100%;
  height: 390px;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor ? backgroundColor : 'rgba(0, 0, 0, 0.35)'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
`;

const LineUIContainer = styled.div`
  width: 100%;
  max-width: 940px !important;
  display: flex;
  margin: 30px 0 15px;
  position: relative;
  border-radius: 3px;
  background-color: #fafcfc;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.05);
  border: solid 1px #eee;
  &::before {
    content: "";
    width: 14.5px;
    height: 51px;
    position: absolute;
    top: -26px;
    left: -16px;
    border-left: 1px solid #d9dad9;
    border-bottom: 1px solid #d9dad9;
  }
`;

const ToolContainer = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 0 10px;
  &:hover {
    color: #5aaeea;
    cursor: pointer;
    > div > svg > g {
      stroke: #5aaeea;
    }
  }
  ${({ isActive }) => isActive && css`
    color: #5aaeea;
    > div > svg > g {
      stroke: #5aaeea;
    }
  `}
  svg{
    margin-bottom: -3px;
  }
`;

const ZoomButtonContainer = styled.div<{ isReadOnly: boolean }>`
  display: flex;
  align-items: center;
  gap: 0 10px;
  ${({ isReadOnly }) => !isReadOnly && css`
    :hover {
      color: #5aaeea;
      cursor: pointer;
      > div > svg > g {
        stroke: #5aaeea;
      }
    }
  `}
  position: absolute;
  right: 0;
  top: 0;
`;

const ImageToolsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 25px;
`;

const LineDetails = styled.div`
  width: 240px;
  padding-left: 20px;
  font-size: 11px;
  white-space: pre;
`;

const LineTools = styled.div<{isModal?: boolean}>`
  font-size: 14px;
  flex-grow: 1;
  position: relative;
  ${({isModal}) => isModal && css`
    background: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
`;

const LineDetailsAndTools = styled.div`
  width: 100%;
  max-width: 940px !important;
  display: flex;
`;

const LineFormatter = styled.div`
`;

const ImageFormatter = styled.div`
  img{
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
  }
  svg{
    max-height: calc(100vh - 100px);
    max-width: calc(100vw - 100px);
  }
`;

//pointer event commented temporary
const LineUIImageWrapperModal = styled.div<{isClickable: boolean, isLineConfigurable: boolean}>`
  width: 100%;
  height: inherit;
  position: relative;
  ${({isLineConfigurable}) => !isLineConfigurable && css`
    // pointer-events: none;
  `}
  ${({isClickable}) => isClickable && css`
    // pointer-events: unset;
    cursor: pointer;
  `}
  & > div {
    height: inherit;
    > img {
      max-height: 80vh;
    }
  }
  background-color: hsl(202deg 15% 91%);
`;

const Formatter = styled.div`
  padding: 10px;
`;

interface ISmallInput {
  length: string;
}

interface ILineDetails {
  lineName: string,
  directionDetails: {
    directionOption: string,
    forward: string,
    backward: string
  },
  vehicleWidth: number | undefined
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

interface ILinePoints {
  points: IPoints,
  changeLineStyle: boolean
}
interface IProps {
  streamName: string,
  isReadOnly: boolean,
  trafficCounterData: ITrafficCounterData,
  getUpdatedTrafficCounterData: (value: ITrafficCounterData) => void;
  cameraDetails: ICameraDetails;
  isLineConfigurable?: boolean,
}

const TrafficCounterTab: React.FC<IProps> = ({ streamName, isReadOnly, trafficCounterData, getUpdatedTrafficCounterData, cameraDetails, isLineConfigurable = false, }) => {
  const { t } = useTranslation(['CommonDict']);
  const [isMount, setIsMount] = useState<boolean>(true);
  const [state, dispatch] = useReducer(LineReducer, []);
  const [lineDetails, setLineDetails] = useState<ILineDetails[]>([]);
  const [directionOption, setDirectionOption] = useState<string>('Automatic');
  const [periodicSnapshot, setPeriodicSnapshot] = useState<number | undefined>(60);
  const [isAnalysisEnabled, setIsAnalysisEnabled] = useState<boolean>(false);
  const [linePoints, setLinePoints] = useState<IPoints[]>([]);
  const [isTrafficCounterDataUpdated, setIsTrafficCounterDataUpdated] = useState<boolean>(true);
  const [isImageAvailable, setIsImageAvailable] = useState<boolean>(false);
  const [isLineRemoved, setIsLineRemoved] = useState<boolean>(false);
  const [lineSelectOptions, setLineSelectOptions] = useState<string[]>(['[New Line]']);
  const [lineReference, setLineReference] = useState<string>('');
  const [directionLabel, setDirectionLabel] = useState<{ forward: string, backward: string }>({ forward: '', backward: '' });
  const [vehicleWidth, setVehicleWidth] = useState<number | undefined>();
  const [selectedLine, setSelectedLine] = useState<string>('[New Line]');
  const [selectedLineIndex, setSelectedLineIndex] = useState<number>(0);
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [isLiveFeed, setIsLiveFeed] = useState<boolean>(false);
  const [imageDetails, setImageDetails] = useState({ x: 1920, y: 1080 });
  const [prevImageDetails, setPrevImageDetails] = useState(imageDetails);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [image, setImage] = useState('');
  const [noImage, setNoImage] = useState(false);
  const userName = TokenService.getUser();
  const [boundingBoxes, setBoundingBoxes] = useState(JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string));
  const { loadingFrame, frame, action: { stopFrame, startFrame } } = useWebsocketFrame(boundingBoxes ? '/websocket-sink-tracking/socket.io' : '/websocket-sink/socket.io', streamName);
  const { isMediaUrlValid } = useMediaModal();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';
  const [isStopFrame, setIsStopFrame] = useState(false);
  const [modalState, setModalState] = useState<boolean>(false);
  const isModal = true;
  const [ showImage, setShowImage ] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);
  const single_click_timeout: any = useMemo(()=>{
    return ;
  }, []);
  const single_click_timeoutRef = useRef(single_click_timeout);

  useClickOutside(imageRef, () => {
    setShowImage(false);
    setModalState(false);
  });

  const getLinePoints = useCallback((points: IPoints): ILinePoints => {
    let changeLineStyle = false;
    const boundaryOffset = Math.round(imageDetails.x * 2.5 / 100); // 48

    // checks if any point goes outside the boundary of image and trims those points
    if (
      (points.start_x < boundaryOffset || points.start_x > (imageDetails.x - boundaryOffset)) ||
      (points.start_y < boundaryOffset || points.start_y > (imageDetails.y - boundaryOffset)) ||
      (points.end_x < boundaryOffset || points.end_x > (imageDetails.x - boundaryOffset)) ||
      (points.end_y < boundaryOffset || points.end_y > (imageDetails.y - boundaryOffset))
    ) {
      changeLineStyle = true;
    }
    points.start_x = points.start_x < boundaryOffset ? boundaryOffset : points.start_x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points.start_x;
    points.start_y = points.start_y < boundaryOffset ? boundaryOffset : points.start_y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points.start_y;
    points.end_x = points.end_x < boundaryOffset ? boundaryOffset : points.end_x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points.end_x;
    points.end_y = points.end_y < boundaryOffset ? boundaryOffset : points.end_y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points.end_y;

    return {points, changeLineStyle};
  }, [imageDetails]);

  const importSettings = useCallback((importedTrafficCounterData: ITrafficCounterData) => {
    const { analysis_enabled, periodic_snapshot_interval = 60, lines = [] } = importedTrafficCounterData;
    if (periodic_snapshot_interval < 60) {
      setPeriodicSnapshot(1);
    } else if (periodic_snapshot_interval > 3600) {
      setPeriodicSnapshot(60);
    } else {
      setPeriodicSnapshot(Math.floor(periodic_snapshot_interval / 60));
    }
    setIsAnalysisEnabled(analysis_enabled);
    if (lines.length > 0) {
      const lineInfo: ILineDetails[] = [];
      setLineSelectOptions(['[New Line]']);
      const state = lines.map((item, index) => {
        const point = cloneDeep(item.points);
        const { points, changeLineStyle } = getLinePoints(point);
        const lineName = !item.name ? `Line${index}` : item.name.slice(0, 24);
        const forward = item.direction_type === 'Manual' && !item.fwd_direction_label ? `FwdLabel-line${index}` : item.fwd_direction_label.slice(0, 64);
        const backward = item.direction_type === 'Manual' && !item.back_direction_label ? `BackLabel-line${index}` : item.back_direction_label.slice(0, 64);

        setLineSelectOptions(prev => [...prev, lineName]);

        lineInfo.push(
          {
            lineName,
            directionDetails: {
              directionOption: item.direction_type,
              forward,
              backward
            },
            vehicleWidth: !item.large_vehicle_width ? undefined : item.large_vehicle_width <= 0 ? 1 : item.large_vehicle_width > 1920 ? 1920 : item.large_vehicle_width
          }
        );
        return {
          name: index + 1 + ': ' + lineName,
          points: [
            { x: points.start_x, y: points.start_y },
            { x: points.end_x, y: points.end_y }
          ],
          readOnly: true,
          styling: changeLineStyle ? 'danger' : 'primary',
          showSmallDirectionMark: true
        };
      });
      dispatch({
        type: 'LOAD',
        state
      });
      setLineDetails(lineInfo);
    } else {
      setLineDetails([]);
    }
  }, [getLinePoints]);

  // checks if trafficCounterData changed
  useEffect(() => {
    if (trafficCounterData) {
      setIsTrafficCounterDataUpdated(true);
    }
  }, [trafficCounterData]);

  // updates line settings on mount and on change of trafficCounterData
  useEffect(() => {
    if (isTrafficCounterDataUpdated) {
      importSettings(trafficCounterData);
      setIsTrafficCounterDataUpdated(false);
    }
  }, [isTrafficCounterDataUpdated, trafficCounterData, importSettings, streamName]);

  // checks if line goes outside boundary and updates line, when image resolution changes
  useEffect(() => {
    setLinePoints(state.map(item => {
      return {
        start_x: item.points[0].x,
        start_y: item.points[0].y,
        end_x: item.points[1].x,
        end_y: item.points[1].y
      };
    }));
  }, [state]);

  useEffect(() => {
    if (!isEqual(imageDetails, prevImageDetails) && !imageLoading && !noImage) {
      setIsImageAvailable(true);
      setPrevImageDetails(imageDetails);
    }
  }, [prevImageDetails, imageDetails, imageLoading, noImage]);

  useEffect(() => {
    if (isImageAvailable) {
      linePoints.forEach((item,index) => {
        const {points, changeLineStyle} = getLinePoints(item);
        dispatch({
          type: 'UPDATE_SET_OPTIONS',
          index: index,
          data: {
            points: [
              { x: points.start_x, y: points.start_y },
              { x: points.end_x, y: points.end_y }
            ],
            styling: changeLineStyle ? 'danger' : 'primary'
          }
        });
      });
      setIsImageAvailable(false);
    }
  }, [isImageAvailable, linePoints, getLinePoints]);

  const generateTrafficCounterData = useCallback(() => {
    const lines = lineDetails.map((item, index) => {
      return {
        idx: index + 1,
        direction_type: item.directionDetails.directionOption,
        direction_type_auto: item.directionDetails.directionOption === 'Automatic',
        fwd_direction_label: item.directionDetails.forward,
        back_direction_label: item.directionDetails.backward,
        large_vehicle_width: typeof(item.vehicleWidth) === 'number' ? item.vehicleWidth : null,
        name: item.lineName,
        points: {
          start_x: state[index].points[0].x,
          start_y: state[index].points[0].y,
          end_x: state[index].points[1].x,
          end_y: state[index].points[1].y
        }
      };
    });
    const data = {
      camera_name: trafficCounterData.camera_name,
      analysis_enabled: isAnalysisEnabled,
      periodic_snapshot_interval: periodicSnapshot ? (periodicSnapshot * 60) : periodicSnapshot,
      lines
    };
    return data;
  }, [trafficCounterData, isAnalysisEnabled, periodicSnapshot, lineDetails, state]);

  useEffect(() => {
    getUpdatedTrafficCounterData(generateTrafficCounterData());
  }, [generateTrafficCounterData, getUpdatedTrafficCounterData]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
        const isImageValid = await isMediaUrlValid(imgBase64, 'img');
        if (isImageValid === true) {
          const img = new Image();
          img.src = imgBase64;
          setImage(imgBase64);
          setImageDetails({
            x: img.naturalWidth,
            y: img.naturalHeight
          });
          setImageLoading(false);
          setNoImage(false);
        } else {
          setImageLoading(false);
          setNoImage(true);
        }
      } else {
        setImageLoading(false);
        setNoImage(true);
      }
    } catch (err) {
      setNoImage(true);
      setImageLoading(false);
      console.error(err);
    }
  }, [isMediaUrlValid, streamName]);

  const updateParentSnapshot = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}stacks/${streamName}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      const imgData = res.data;
      if (res.status === 200 && imgData) {
        if (imgData.byteLength > 5) { // check for not null response
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          setImage(imgBase64);
          setNoImage(false);
        } else {
          setNoImage(true);
        }
      } else {
        setNoImage(true);
      }
    } catch (err) {
      setNoImage(true);
      console.error(err);
    }
    setIsLiveFeed(false);
  }, [streamName]);

  usePoll(async () => {
    if (!isLiveFeed) {
      await getCameraImage();
    }
  }, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL * 1000);

  const onLiveFeed = useCallback(() => {
    setIsLiveFeed(true);
    startFrame(image);
  }, [image, startFrame]);

  const onLatestSnapsot = useCallback(() => {
    updateParentSnapshot();
    stopFrame();
  }, [stopFrame, updateParentSnapshot]);

  useEffect(() => {
    if (isMount && (image !== '' || !(imageLoading || noImage))) {
      onLiveFeed();
      setIsMount(false);
    }
  }, [isMount, image, imageLoading, noImage, onLiveFeed]);
  
  const updateLine = useCallback((lineName: string, index: number) => {
    dispatch({
      type: 'RENAME_SET',
      index,
      data: {
        name: lineName,
      }
    });
  }, []);

  const addLine = useCallback((lineName: string) => {
    dispatch({
      type: 'ADD_SET',
      data: {
        name: lineName,
        points: [
          {
            x: 150,
            y: 210,
          },
          {
            x: 150,
            y: 65,
          },
        ],
        readOnly: false,
        styling: 'primary',
        showSmallDirectionMark: true
      }
    });
  }, []);

  // we might need to change condition based on index
  const readOnlyLines = useCallback((lineName: string) => {
    lineDetails.forEach((item, index) => {
      dispatch({
        type: 'UPDATE_SET_OPTIONS',
        index: index,
        data: {
          readOnly: (lineName === '[New Line]') ? true : !(lineName === (index + 1 + ': ' + item.lineName)),
          showPointHandle: (lineName === '[New Line]') ? false : (lineName === (index + 1 + ': ' + item.lineName))
        }
      });
    });
  }, [lineDetails]);

  useEffect(() => {
    readOnlyLines(selectedLine);
  }, [selectedLine, readOnlyLines]);

  const onLineSelect = useCallback((e: string) => {
    setSelectedLine(e);
    if (e === '[New Line]') {
      setSelectedLineIndex(0);
    } else {
      setSelectedLineIndex(parseInt(e.substring(0, e.indexOf(':'))));
    }
  }, []);

  // This will update line details on change of any detail like (name, large vehicle width, direction, swapping of line)
  useEffect(() => {
    if (selectedLineIndex !== 0) {
      const lineName = lineDetails[selectedLineIndex - 1].lineName;
      setLineReference(lineName);
      setLineSelectOptions(prev => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp[selectedLineIndex] = lineName;
        return temp;
      });
      if (!lineDetails[selectedLineIndex - 1].vehicleWidth) {
        setVehicleWidth(parseInt(''));
      } else {
        setVehicleWidth(lineDetails[selectedLineIndex - 1].vehicleWidth);
      }
      onLineSelect(selectedLineIndex + ': ' + lineName);
      updateLine(selectedLineIndex + ': ' + lineName, (selectedLineIndex - 1));
    } else {
      setLineReference('');
      setDirectionOption('Automatic');
      setVehicleWidth(parseInt(''));
    }
  }, [selectedLineIndex, lineDetails, onLineSelect, updateLine]);

  useEffect(() => {
    if (selectedLineIndex !== 0) {
      if (lineDetails[selectedLineIndex - 1].directionDetails.directionOption === 'Automatic') {
        setDirectionLabel({ forward: '', backward: '' });
      }
    } else {
      if (directionOption === 'Automatic') {
        setDirectionLabel({ forward: '', backward: '' });
      }
    }
  }, [selectedLineIndex, directionOption, lineDetails]);

  const onDirectionSelect = useCallback((e) => {
    if (selectedLine !== '[New Line]') {
      setLineDetails(prev => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp[selectedLineIndex - 1] = {
          ...temp[selectedLineIndex - 1],
          directionDetails: { directionOption: e, ...directionLabel }
        };
        return temp;
      });
    } else {
      setDirectionOption(e);
    }
  }, [selectedLine, selectedLineIndex, directionLabel]);

  const onLineReferenceChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedLineIndex !== 0) {
      setLineDetails(prev => {
        const temp = JSON.parse(JSON.stringify(prev));
        temp[selectedLineIndex - 1] = {
          ...temp[selectedLineIndex - 1],
          lineName: value
        };
        return temp;
      });
    } else {
      setLineReference(value);
    }
  }, [selectedLineIndex]);

  const onVehicleWidthChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const regExNumberBetween1to1920 = /\b([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|1[0-8][0-9][0-9]|19[0-1][0-9]|1920)\b/;
    if (regExNumberBetween1to1920.exec(value) || value === '') {
      if (selectedLineIndex !== 0) {
        setLineDetails(prev => {
          const temp = JSON.parse(JSON.stringify(prev));
          temp[selectedLineIndex - 1] = {
            ...temp[selectedLineIndex - 1],
            vehicleWidth: parseInt(value)
          };
          return temp;
        });
      } else {
        setVehicleWidth(parseInt(value));
      }
    }
  }, [selectedLineIndex]);

  const onPeriodicSnapshotChange = useCallback(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const regExNumberBetween1to60 = /\b([1-9]|[1-5][0-9]|60)\b/;

    if (regExNumberBetween1to60.exec(value) || value === '') {
      setPeriodicSnapshot(parseInt(value));
    }
  }, []);

  const selectLine = useCallback((id:number) => {
    const linename = `${(id+1)}: ${lineSelectOptions[id + 1]}`;
    if(linename !== 'NaN: undefined'){
      onLineSelect(linename);
    }
  }, [lineSelectOptions, onLineSelect]);

  const onLineClickCallback = useCallback((lineId: number) => {
    window.clearTimeout(single_click_timeoutRef.current);
    selectLine(lineId);
    const deselectLineIndex = state.findIndex((item) => item.showPointHandle);
    dispatch({
      type: 'UPDATE_SET_OPTIONS',
      index: deselectLineIndex,
      data: {
        showPointHandle: false,
        showMoveHandle: false
      }
    });
    dispatch({
      type: 'UPDATE_SET_OPTIONS',
      index: lineId,
      data: {
        showPointHandle: true,
        showMoveHandle: true
      }
    });
  }, [state, selectLine]);

  const onDirectionManualChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    if (selectedLineIndex !== 0) {
      setLineDetails(prev => {
        const temp: ILineDetails[] = JSON.parse(JSON.stringify(prev));
        temp[selectedLineIndex - 1] = {
          ...temp[selectedLineIndex - 1],
          directionDetails: { ...temp[selectedLineIndex - 1].directionDetails, [name]: value }
        };
        return temp;
      });
    } else {
      setDirectionLabel(prevVal => {
        return { ...prevVal, [name]: value };
      });
    }
  }, [selectedLineIndex]);

  const onCameraToggle = useCallback((e: boolean) => {
    setIsAnalysisEnabled(e);
  }, []);

  const onAddLine = useCallback(() => {
    if (lineReference !== '') {
      if (directionOption !== 'Automatic') {
        if (directionLabel.forward === '') {
          sendNotificationRef.current({
            type: 'error',
            message: t('Please enter forward label.')
          });
          return;
        } else if (directionLabel.backward === '') {
          sendNotificationRef.current({
            type: 'error',
            message: t('Please enter backward label.')
          });
          return;
        }
      }
      const lineDetail = {
        lineName: lineReference,
        directionDetails: { directionOption, ...directionLabel },
        vehicleWidth
      };
      setLineSelectOptions(prev => [...prev, lineReference]);
      onLineSelect(state.length + 1 + ': ' + lineReference);
      addLine(state.length + 1 + ': ' + lineReference);
      setLineDetails(prev => [...prev, lineDetail]);

      sendNotificationRef.current({
        type: 'neutral',
        message: t('New Line Added'),
        icon: 'FeatureLineUi'
      });
    }
  }, [state, lineReference, directionOption, directionLabel, vehicleWidth, addLine, onLineSelect, t]);

  // This will update index of all lines on removal of any line
  useEffect(() => {
    if (lineDetails.length !== 0 && isLineRemoved) {
      lineDetails.forEach((item, index) => {
        dispatch({
          type: 'RENAME_SET',
          index,
          data: {
            name: `${index + 1}: ${item.lineName}`
          }
        });
      });
      setIsLineRemoved(false);
    }
  }, [lineDetails, isLineRemoved]);

  const remLine = useCallback((index: number) => {
    setLineDetails(prev => prev.filter((_, index2) => index2 !== index));
    setLineSelectOptions(prev => prev.filter((_, index2) => index2 !== (index + 1)));
    setIsLineRemoved(true);
    dispatch({
      type: 'REMOVE_SET',
      index
    });
    onLineSelect('[New Line]');
    sendNotificationRef.current({
      type: 'neutral',
      message: t('Line Removed'),
      icon: 'FeatureLineUi'
    });
  }, [t, onLineSelect]);

  const getLinePosition = useCallback(() => {
    if (selectedLine !== '[New Line]') {
      const index = selectedLineIndex - 1;
      return `${'(' +
        state[index].points[0].x +
        ' , ' +
        state[index].points[0].y +
        ') - (' +
        state[index].points[1].x +
        ' , ' +
        state[index].points[1].y +
        ')'
      }`;
    } else {
      return '';
    }
  }, [state, selectedLine, selectedLineIndex]);

  const swapLineDirection = useCallback(() => {
    const index = selectedLineIndex - 1;
    dispatch({
      type: 'UPDATE_SET_POINTS',
      index: index,
      data: {
        points: [
          {
            x: state[index].points[1].x,
            y: state[index].points[1].y,
          },
          {
            x: state[index].points[0].x,
            y: state[index].points[0].y,
          },
        ],
      },
    });
  }, [state, selectedLineIndex]);

  const onExportSettings = useCallback(() => {
    const { analysis_enabled, lines } = trafficCounterData;
    const exportLines = lines.map(({direction_type_auto, ...rest}) => rest);
    try {
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ analysis_enabled, lines: exportLines }, null, 4));
      const link = document.createElement('a');
      link.setAttribute('href', dataStr);
      link.setAttribute('download', 'detection_lines.json');
      link.click();
      sendNotification({
        type: 'success',
        message: t('Data exported successfully.')
      });
    } catch (error) {
      sendNotification({
        type: 'error',
        message: t('An error occurred while exporting data.')
      });
    }
  }, [trafficCounterData, sendNotification, t]);

  const onImportCallback = useCallback((importedTrafficCounterData: ITrafficCounterDataImport) => {
    const periodic_snapshot_interval = periodicSnapshot !== undefined ? periodicSnapshot : 1;
    onLineSelect('[New Line]');
    setLineDetails([]);
    importSettings({periodic_snapshot_interval: (periodic_snapshot_interval * 60), ...importedTrafficCounterData});
    setModalOpen(false);
    sendNotification({
      type: 'success',
      message: t('Data imported successfully.')
    });
  }, [periodicSnapshot, setModalOpen, importSettings, t, sendNotification, onLineSelect]);

  // Import Modal
  const getImportModal = useCallback(() => {
    return (
      <ImportSettingsModal {...{ onImportCallback, sendNotification }} />
    );
  }, [sendNotification, onImportCallback]);

  const onImportSettings = useCallback(() => {
    setModalOpen(true);

    const importModal: ReactElement = getImportModal();

    createModal({
      isCloseEnable: false,
      width: 'max-length',
      padding: false,
      customComponent: importModal,
    });
  }, [createModal, setModalOpen, getImportModal]);




  const onZoom = useCallback(() => {
    setShowImage(!showImage);
    setModalState(true);
  }, [showImage]);

  useEffect(()=>{
    if(isModalOpen){
      setIsStopFrame(true);
    } else{
      setIsStopFrame(false);
    }
  }, [isModalOpen]);

  useEffect(()=>{
    if(isStopFrame){
      stopFrame();
    }else {
      if(isLiveFeed){
        startFrame(image);
      }
    }
  }, [isStopFrame, stopFrame, startFrame, image, isLiveFeed]);

  
  useEffect(()=>{   //eslint-disable-line
    const { status, usb_device_status } = cameraDetails;
    if(usb_device_status || status){
      if(
        status?.status_code === 30001 || 
        status?.status_code === 30003 || 
        status?.status_code === 30002 || 
        status?.status_code === 20005 || 
        status?.status_code === 20002 || 
        status?.status_code === 20003 ||
        status?.status_code === 20004 ||
        status?.status_code === 20005 ||
        status?.status_code === 20006 ||
        status?.status_code === 20007 ||
        status?.status_code === 40000){
        setBoundingBoxes(false);
      }else {
        setBoundingBoxes(JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string));
      }
    }
  });

  const handleDismiss =()=>{
    setModalState(false);
  };

  return (
    <>
      <SettingsContainer>
        <InputAndButtonContainer gap='0 14px'>
          {t('{Configuration}Line Settings').replace('{Configuration}', '')}:
          <Button size='xsmall' design='secondary' disabled={isReadOnly} onClick={onImportSettings}>
            {t('Import')}
          </Button>
          <Button size='xsmall' design='secondary' disabled={isReadOnly} onClick={onExportSettings}>
            {t('Export')}
          </Button>
        </InputAndButtonContainer>
        <PeriodicAndCameraEnabled>
          <SnapshotSetting>
            {t('Periodic Snapshot Interval')} :
            <StyledSmallInput
              fieldState='default'
              name='periodicSnapshot'
              type='number'
              length='70px'
              min={1}
              max={60}
              value={periodicSnapshot}
              label=''
              unit={t('min')}
              disabled={isReadOnly}
              onChange={onPeriodicSnapshotChange}
            />
          </SnapshotSetting>
          <SwitchContainer>
            <Switch
              checked={isAnalysisEnabled}
              labelText={isAnalysisEnabled ? t('Enabled') : t('Disabled')}
              leftTheme='off'
              onChangeCallback={(e) => onCameraToggle(e)}
              rightTheme='on'
              state={isReadOnly ? 'disabled' : 'default'}
            />
          </SwitchContainer>
        </PeriodicAndCameraEnabled>
      </SettingsContainer>
      <Divider />
      <LineSettingsContainer>
        <LineSelectFieldContainer>
          <Icon icon='FeatureLineUi' size={16} color='dimmed' />
          <StyledSelectField
            changeCallback={(e) => onLineSelect(e)}
            disabled={isReadOnly}
            isCompact
            value={selectedLine}
          >
            {
              lineSelectOptions.map((item, index) => {
                return (
                  <option key={item + index} value={item === '[New Line]' ? item : `${index}: ${item}`}>
                    {item === '[New Line]' ? t(item) : `${index}: ${item}`}
                  </option>
                );
              })
            }
          </StyledSelectField>
          <InputAndButtonContainer gap='0 10px'>
            <ButtonWithIcon
              icon='Add'
              position='left'
              size='small'
              design='secondary'
              onClick={onAddLine}
              disabled={selectedLineIndex !== 0 || state.length === 20 ||
                isReadOnly || lineReference === ''}
            >
              {t('Add Line')}
            </ButtonWithIcon>
            <ButtonWithIcon
              icon='ActionRemove'
              position='left'
              size='small'
              design='secondary'
              onClick={() => remLine(selectedLineIndex - 1)}
              disabled={selectedLineIndex === 0 || isReadOnly}
            >
              {t('Remove Line')}
            </ButtonWithIcon>
          </InputAndButtonContainer>
          
        </LineSelectFieldContainer>
        <LineUIContainer>
          <LineUILeftPanel>
            <SidePaddingDiv>
              <StyledSmallInputWithLabel
                fieldState='default'
                label={`${selectedLineIndex === 0 ? t('Line Reference') : t('Line') +' #'+selectedLineIndex}`}
                name='lineReference'
                length='100%'
                maxLength={24}
                disabled={isReadOnly}
                value={selectedLineIndex === 0 ? lineReference : lineDetails[selectedLineIndex - 1].lineName}
                onChange={(e) => onLineReferenceChange(e)}
              />
            </SidePaddingDiv>
            <DividerWithMargin />
            <SidePaddingDiv>
              <DirectionSelectFieldContainer>
                <DirectionSelectField
                  label={{
                    htmlFor: 'directionDetection',
                    text: t('Direction Detection'),
                  }}
                  changeCallback={(e) => onDirectionSelect(e)}
                  value={selectedLineIndex === 0 ? directionOption : lineDetails[selectedLineIndex - 1].directionDetails.directionOption}
                  disabled={isReadOnly}
                  isCompact
                >
                  <option value='Automatic'>{t('Automatic')}</option>
                  <option value='Manual'>{t('Manual')}</option>
                </DirectionSelectField>
              </DirectionSelectFieldContainer>
              {(
                selectedLineIndex === 0 ?
                  directionOption === 'Manual'
                  :
                  lineDetails[selectedLineIndex - 1].directionDetails.directionOption === 'Manual'
              ) && (
                <>
                  <DirectionInputField>
                    <StyledInput
                      fieldState='default'
                      name='forward'
                      placeholder='Forward'
                      maxLength={64}
                      disabled={isReadOnly}
                      length='100%'
                      value={selectedLineIndex === 0 ? directionLabel.forward : lineDetails[selectedLineIndex - 1].directionDetails.forward}
                      onChange={onDirectionManualChange}
                    />
                    <Circle size={0.65}>
                      <Icon color='inverse' icon='Up' size={12} weight='heavy' />
                    </Circle>
                  </DirectionInputField>
                  <DirectionInputField>
                    <StyledInput
                      fieldState='default'
                      name='backward'
                      placeholder='Backward'
                      maxLength={64}
                      disabled={isReadOnly}
                      length='100%'
                      value={selectedLineIndex === 0 ? directionLabel.backward : lineDetails[selectedLineIndex - 1].directionDetails.backward}
                      onChange={onDirectionManualChange}
                    />
                    <Circle size={0.45}>
                      <Icon color='inverse' icon='Down' size={12} weight='heavy' />
                    </Circle>
                  </DirectionInputField>
                </>
              )}
              <ColoredButton size='xsmall' design='secondary' onClick={() => swapLineDirection()} disabled={selectedLineIndex === 0 || isReadOnly}>
                {t('Swap Direction')}
              </ColoredButton>
            </SidePaddingDiv>
            <DividerWithMargin />
            <SidePaddingDiv>
              <LabelWithoutMargin
                htmlFor='vehicleWidth'
                labelText={t('Large Vehicle Width')}
              />
              <StyledSmallInput
                fieldState='default'
                name='vehicleWidth'
                type='number'
                min={1}
                max={1920}
                disabled={isReadOnly}
                length='95px'
                label=''
                unit='px'
                value={(selectedLineIndex === 0 || lineDetails[selectedLineIndex - 1].vehicleWidth === null) ? vehicleWidth : (lineDetails[selectedLineIndex - 1]?.vehicleWidth !== undefined ? lineDetails[selectedLineIndex - 1]?.vehicleWidth: NaN)}
                onChange={e => onVehicleWidthChange(e)}
              />
            </SidePaddingDiv>
          </LineUILeftPanel>
          <LineUIRightPanel>
            <LineUIImageWrapper isReadOnly={isReadOnly}>
              <LineSetContext.Provider value={{ state, dispatch }}>
                {imageLoading ?
                  <SpinnerContainer>
                    <Spinner size='large' styling='primary' />
                  </SpinnerContainer>
                  :
                  <div>
                    <LineUI
                      options={{
                        showSetIndex: false,
                        showPoint: true,
                        showDirectionMark: true,
                        showMoveHandle: false,
                        showPointHandle: true,
                        setIndexOffset: 0,
                        boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                        fixedImgDimensions: imageDetails}}
                      src={image === '' && (noImage || (isLiveFeed && !loadingFrame && frame === '')) ?
                        lang === 'ja' ? AwaitingStreamJp : AwaitingStreamEn
                        : isLiveFeed && frame !== '' ? frame : image}
                      onLineClick={onLineClickCallback}
                    />
                  </div>}

                {(modalState && showImage) &&
                  <BoxModal
                    closeText={t('CLOSE')}
                    isOpen={modalState && showImage}
                    onDismiss={()=> handleDismiss()}
                    customeComponent={
                      <LineFormatter>
                        <LineUIImageWrapperModal isClickable={!( modalState || imageLoading || noImage)} isLineConfigurable={isLineConfigurable}>   
                          <div ref={imageRef}>
                            <LineSetContext.Provider value={{ state, dispatch }}>
                              {showImage &&
                                <ImageFormatter>
                                  <LineUI
                                    options={{
                                      showSetIndex: false,
                                      showPoint: true,
                                      showDirectionMark: true,
                                      showMoveHandle: false,
                                      showPointHandle: true,
                                      setIndexOffset: 0,
                                      boundaryOffset: Math.round(imageDetails.x * 2.5 / 100),
                                      fixedImgDimensions: imageDetails
                                    }}
                                    src={isLiveFeed && frame !== '' ? frame : image}
                                    onLineClick={onLineClickCallback}
                                  />
                                </ImageFormatter>}
                            </LineSetContext.Provider>                        
                            {(image !== '' || !(imageLoading || noImage)) &&
                              <LineTools isModal={isModal}>
                                <Formatter>
                                  <ImageToolsContainer>
                                    <ToolContainer onClick={onLatestSnapsot} isActive={!isLiveFeed}>
                                      <Icon icon='FileTypeImage' size={14} color='dimmed' />              
                                      <div>{t('Latest Snapshot')}</div>
                                    </ToolContainer>
                                    <ToolContainer onClick={onLiveFeed} isActive={isLiveFeed}>
                                      {
                                        loadingFrame ?
                                          <Spinner size='small' styling='primary' />
                                          : <Icon icon='Camera' size={14} color='dimmed' />
                                      }
                                      <div>{t('Live Feed')}</div>
                                    </ToolContainer>
                                  </ImageToolsContainer>
                                </Formatter>
                              </LineTools>}
                          </div>
                        </LineUIImageWrapperModal>
                      </LineFormatter>
                    }
                  />}
              </LineSetContext.Provider>
            </LineUIImageWrapper>
          </LineUIRightPanel>
        </LineUIContainer>
        <LineDetailsAndTools>
          <LineDetails>
            {(selectedLineIndex !== 0) && t('LineDetection').replace('Detection', '') + '#' + selectedLineIndex + ' ' + t('Points')}
            <span>{'   ' + getLinePosition()}</span>
          </LineDetails>
          {
            (image !== '' || !(imageLoading || noImage)) &&
              <LineTools>
                <ImageToolsContainer>
                  <ToolContainer onClick={onLatestSnapsot} isActive={!isLiveFeed}>
                    <Icon icon='FileTypeImage' size={14} color='dimmed' />
                    <div>{t('Latest Snapshot')}</div>
                  </ToolContainer>
                  <ToolContainer onClick={onLiveFeed} isActive={isLiveFeed}>
                    {
                      loadingFrame ?
                        <Spinner size='small' styling='primary' />
                        : <Icon icon='Camera' size={14} color='dimmed' />
                    }
                    <div>{t('Live Feed')}</div>
                  </ToolContainer>
                </ImageToolsContainer>
                <ZoomButtonContainer onClick={onZoom} isReadOnly={isReadOnly}>
                  <Icon icon='Search' size={14} color='dimmed' />
                  <span>{t('Zoom')}</span>
                </ZoomButtonContainer>
              </LineTools>
          }
        </LineDetailsAndTools>
      </LineSettingsContainer>
    </>
  );
};

export default TrafficCounterTab;