import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonWithIcon, Content, DateInterval, FilterBar, IFilterDropdownConfig, IFilterItem, IFilterResult, IFilterValue, ISearchFilter, Label, PageTitle, SortDropdown, Spinner, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import Card from 'components/Card';
import { getAllCamera } from 'services/apiConfig';
import TokenService from 'services/tokenService';
import AuthService from 'services/authService';

const Container = styled(Content)`
  @media only screen and (max-width: 1440px) {
    max-width: 1120px;
  }
  user-select: none;
`;

const HeaderBar = styled.div`
  @media only screen and (width: 1920px) {
    max-width: 1421px !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > div > div > div{
    bottom: 2px;
  }
`;

const FilterContainer = styled.div`
  @media only screen and (width: 1920px) {
    max-width: 1421px !important;
  }
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0 43px 0px;
  & > div > div > div:nth-child(2) > div{
    max-width: 214px;
  }
  & > div > div > div:nth-child(3){
    margin-top: 15px;
    margin-right: 233px;
  }
`;

const FilterWrapper = styled.div<{pointerEvent: string, background:string} >`
  flex-grow: 1;
  margin-top: 14px;
  button {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
  input {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
`;

const SortBox = styled.div<{pointerEvent: string, background:string}>`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  white-space: nowrap;
  position: absolute;
  right: 0;
  bottom: -7px;
  button > div > div:nth-child(2){
    min-width: 145px;
    display: flex;
    justify-content: space-around;
  }
  button {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
`;

const CameraListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 60px;
  max-width: fit-content ! important;
`;

const EmptyStateBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 160px;
  border: solid 1px #eee;
  padding: 37px 0;
  @media only screen and (min-width: 1439px) {
    max-width: 1422px !important;
  }
`;

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  border: solid 1px #eee;
  padding: 37px 0;
  @media only screen and (min-width: 1439px) {
    max-width: 1422px !important;
  }
`;

const EmptyStateTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
`;

const EmptyStateSubtitle = styled(Label)`
  font-size: 14px;
  width: 621px;
  text-align: center;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

interface IFilterData {
  name: string;
  status: string;
  paramsLoaded: string;
  [key: string]: string;
}

interface INewParams {
  searchText?: string | null;
  status?: string | null;
  sortBy?: string | null;
}

interface IData {
  camera_enabled: boolean;
  camera_name: string;
  camera_type: string;
  line_configuration: [];
  length: number;
  notes: string;
  status_code: number;
  status_message: string;
  stream_name: string;
  video_configuration:{};
  status: {
    status_category: string;
  }
}

const statusSort: {[key: string]: string} = {
  Running: '1',
  Error: '2',
  Warning: '3',
  Disabled: '4'
};

const FILTER_DATA: IFilterData = { name: '', status: '', paramsLoaded: '' };

const getSelectedFilter = (text: string, value: DateInterval | undefined | string | Date) => (value === '' ? undefined : ({ text, value }));

const Camera: FC = () => {
  const [allCameraData, setAllCameraData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState({ ...FILTER_DATA });
  const [sorting, setSorting] = useState({ value: 'name', ascending: false, text: 'Name' });
  const { push, replace } = useHistory();
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const params = useLocation().search;
  const [historyParams] = useState(params);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);

  const status = ['Running', 'Error', 'Warning', 'Disabled'];
  const options = { showPointHandle: false, showPointLabel: false, showMoveHandle: false, boundaryOffset: 50, showDirectionMark: true };

  const sortByList = [
    {
      value: 'name',
      text: t('Name')
    },
    {
      value: 'status',
      text: t('Status')
    }
  ];


  const getSearchConfig = useCallback((): ISearchFilter[] => {
    return [
      {
        id: 'name',
        placeholder: t('Filter by name...'),
        name: t('Name'),
        selected: filterValues.name ? { text: filterValues.name, value: filterValues.name } : undefined
      }
    ];
  }, [filterValues.name, t]);


  const dropdownConfig: IFilterDropdownConfig[] = [
    {
      id: 'status',
      buttonText: t('Status'),
      buttonIcon: 'ProductLogs',
      optionType: 'radio',
      list: status.map((value) => ({ text: t(value), value: value.toLowerCase() })) as IFilterItem[],
      selected: getSelectedFilter(t(filterValues.status), filterValues.status) as IFilterValue | undefined
    }
  ];

  const fetchAllCamera = useCallback(async () => {
    setLoading(true);
    try {
      const { data: { status_code, data } } = await getAllCamera();
      if (status_code === 200) {
        setAllCameraData(data);
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load camera device') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load camera device') });
      console.error((error as Error).message);
    }
  }, []);

  useEffect(() => {
    fetchAllCamera();
  }, [fetchAllCamera]);

  const handleFilter = useCallback((res: IFilterResult[]) => {
    const searchFilter = { ...FILTER_DATA };
    res.forEach((item) => {
      searchFilter[item.id] = (item.selected as IFilterItem).value as string;
    });
    setFilterValues({ ...searchFilter, paramsLoaded: 'true' });
  }, []);

  const setParams = useCallback(() => {
    const params = '?' +
      `${filterValues.name === '' ? '' : '&searchText=' + filterValues.name}` +
      `${filterValues.status === '' ? '' : '&status=' + filterValues.status}` +
      `${!sorting.ascending && sorting.value === 'name' ? '' : '&sortBy=' + JSON.stringify(sorting)}`;

    if (params === '?') {
      return '';
    }
    return params;
  }, [filterValues, sorting]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams, filterValues]);


  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(historyParams);
    const newParams: INewParams = {};
    newParams.searchText = params.get('searchText');
    newParams.status = params.get('status');
    newParams.sortBy = params.get('sortBy');

    const searchFilter = { ...FILTER_DATA };
    searchFilter.name = newParams.searchText ?? '';
    searchFilter.status = newParams.status ?? '';
    setSorting(prev => (newParams.sortBy ? JSON.parse(newParams.sortBy) : prev));

    searchFilter.paramsLoaded = 'true';
    setFilterValues(searchFilter);
  }, [historyParams]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);


  const compare = useCallback((a, b) => {
    let result: number = 0;
    switch (sorting.value) {
    case 'name':
      result = sorting.ascending ? a.camera_name.localeCompare(b.camera_name, 'en', { numeric: true }) : b.camera_name.localeCompare(a.camera_name, 'en', { numeric: true });
      break;
    case 'status':
      result = sorting.ascending ? statusSort[a.status.status_category]?.toString().localeCompare(statusSort[b.status.status_category]) : statusSort[b.status.status_category]?.toString().localeCompare(statusSort[a.status.status_category]);
      break;
    default:
      result = 0;
    }
    return result;
  }, [sorting]);

  const filterCameraData = useCallback((data = []) => {
    const filterData = data.filter((item: IData) => {
      if (((item.camera_name.toLowerCase().includes(filterValues.name.toLowerCase())) || filterValues.name.length === 0) && (item.status.status_category.toLowerCase().includes(filterValues.status.toLowerCase()))) {
        return item;
      } else {
        return false;
      }
    });
    return filterData.sort(compare);
  }, [filterValues, compare]);

  useEffect(() => {
    const data = filterCameraData(allCameraData);
    setFilterData(data);
  }, [filterCameraData, allCameraData, filterValues]);

  const onChangeSorting = useCallback((event, ascending) => {
    setSorting({ value: event.value.toString(), text: event.text, ascending });
  }, []);

  if (loading) {
    return (
      <SpinnerBox>
        <Spinner size='large' styling='primary' />
        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
      </SpinnerBox>
    );
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Container>
      <HeaderBar>
        <div>
          <PageTitle
            icon='DevicesScorerCamera'
            title={t('Cameras')}
            updateDocTitle={false}
          />
        </div>
        {TokenService.getUserType() &&
          <ButtonContainer>
            <ButtonWithIcon
              design='primary'
              icon='Add'
              onClick={() => push('/add-camera')}
              position='left'
              size='small'
            >
              {t('Add Camera')}
            </ButtonWithIcon>
          </ButtonContainer>}
      </HeaderBar>
      <FilterContainer>
        <FilterWrapper pointerEvent={allCameraData.length === 0 ? 'none' : 'auto'} background={allCameraData.length === 0  ? '0.5' : ''}>
          {filterValues.paramsLoaded === 'true' &&
            <FilterBar
              resultTextTemplate={`${t('Showing All')} (${filterData.length}):`} filtersTitle={t('Filters') + ':'}
              totalResults={filterData.length} searchersConfig={getSearchConfig()} dropdownsConfig={dropdownConfig} onChangeCallback={handleFilter}
              clearText={t('CLEAR ALL')}
            />}
        </FilterWrapper>
        <SortBox pointerEvent={allCameraData.length === 0 ? 'none' : 'auto'} background={allCameraData.length === 0  ? '0.5' : ''}>
          <SortDropdown
            ascendingText={t('Ascending')}
            buttonText={t('Sorted By') + ' ' + t(capitalizeFirstLetter(sorting.value))}
            descendingText={t('Descending')}
            isSortAscending={sorting.ascending}
            list={sortByList}
            onSelect={onChangeSorting}
            selected={{ text: sorting.text, value: sorting.value }}
          />
        </SortBox>
      </FilterContainer>

      {filterData.length > 0 ?
        <CameraListBox>
          {filterData.map((item: IData, index: number) => (
            <Card key={index} data={item} options={options} />
          ))}
        </CameraListBox>
        :
        (filterValues.name.trim() !== '' || filterValues.status.trim() !== '' ?  
          <EmptyBox>
            <EmptyStateTitle htmlFor='' labelText={t('No camera found')} />
          </EmptyBox>
          :
          <EmptyStateBox>
            <EmptyStateTitle htmlFor='' labelText={t('Add Cameras to Begin')} />
            <EmptyStateSubtitle htmlFor='' labelText={t('The system currently has no cameras registered. To begin, use the “Add Camera” button above to get started. Once cameras have been added they will appear here.')} />
          </EmptyStateBox>)}
    </Container>
  );
};

export default Camera;