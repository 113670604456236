import React, { useState, useEffect, useCallback, FC } from 'react';
import {
  Route,
  Redirect
} from 'react-router-dom';
import {useHistory, useLocation} from 'react-router';
import SecureRoute from 'SecureRoute';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { MainMenu, Layout, TopBar, Spinner, Label } from 'scorer-ui-kit';
import { IMenuItemSubmenu, IMenuItemTop, IMenuTop } from 'scorer-ui-kit/dist/Global';
import styled from 'styled-components';


import logoMarkSvg from './svg/logo-mark.svg';
import LogoTextAppNameEn from './svg/logo-app-name_en.svg';
import menu from './pages/menu.json';

import Login from 'pages/Login';
import TokenService from 'services/tokenService';
import AddCamera from 'pages/AddCamera';
import SafieIntegration from 'pages/SafieIntegration';
import CloudUploadSettings from 'pages/CloudUploadSettings';
import { SUPERSET_URL, SUPERSET_URL_DASHBOARD_1, SUPERSET_URL_DASHBOARD_2, SUPERSET_URL_DASHBOARD_3 } from './constants';
import { getGuestUser, getHealthStatusDetails, getEdgeDeviceDetails } from './services/apiConfig';

import CameraDetails from 'pages/CameraDetails';
import Export from 'pages/Export';
import Cameras from 'pages/Cameras';
import CameraConfiguration from 'pages/CameraConfiguration';
import UserManagement from 'pages/User';
import CreateEditUser from 'pages/User/CreateEditUser';
import clonedeep from 'lodash.clonedeep';
import PasswordModal from 'components/PasswordModal';
import DiskFullNotification from './components/DiskFullNotification';
import Role from 'components/Role';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const MainMenuContainer = styled(MainMenu)`
  user-select: none;
`;

const SpinnerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

interface IEdgeDevice {
  device_id:string,
  version: string,
}

const EDEGE_DEVICE_DATA = {
  device_id:'',
  version: '',
};

const App: FC = () => {

  const { t } = useTranslation(['CommonDict']);
  const [menus, setMenus] = useState<IMenuTop>({ items: [] });
  const [userType, setUserType] = useState(false);
  const [isDiskFull, setIsDiskFull] = useState<boolean>(false);
  const [guestUser, setGuestUser] = useState<boolean>(false);
  const [loadingGuest, setLoadingGuest] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!(TokenService?.getUser()) ? false : true);
  const location = useLocation();
  const history = useHistory();
  const userName = TokenService.getUser();
  const [boundingBoxes, setBoundingBoxes] = useState(typeof(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) === 'string' ? JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) : true );
  const [edgeDeviceData, setEdgeDeviceData] = useState<IEdgeDevice>(EDEGE_DEVICE_DATA);
  const deviceText = i18n.language === 'ja' ? 'デバイスID' : 'Device ID';
  const versionInfo = {
    icon: 'Information',
    title: edgeDeviceData.version ? edgeDeviceData.version : '-'};
  const deviceInfo = [
    {
      icon: 'Information',
      title: deviceText,
      subTitle: edgeDeviceData.device_id ? edgeDeviceData.device_id : '-',
      notes:'',
      hasCopyIcon:true
    }
  ];

  const fetchCameraDetails = useCallback(async () => {
    try {
      const  { data, status }  = await getEdgeDeviceDetails();
      if(status === 200){
        setEdgeDeviceData({device_id: data.message['device-id'], version:data.message['software-version']});
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }, []);

  useEffect(()=>{
    fetchCameraDetails();
  }, [fetchCameraDetails]);

  const fetchGuestData = useCallback(async () => {
    setLoadingGuest(true);
    try {
      const { data: { data, status_code } } = await getGuestUser();
      if (status_code === 200) {
        setGuestUser(data.guest_enabled);
      } else {
        setGuestUser(false);
      }
    } catch (error) {
      setGuestUser(false);
      console.error((error as Error).message);
    } finally {
      setLoadingGuest(false);
    }
    setBoundingBoxes(typeof(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) === 'string' ? JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string) : true );
  }, [userName]);

  const onLanguageChange = useCallback(() => {
    const language = i18n.language === 'ja' ? 'en' : 'ja';
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  }, []);

  const translateMenus = useCallback(() => {
    const menuData = [...menu.items, { 'icon': 'Metabase', 'title': 'Visualization', isExternalLink: true, 'href': SUPERSET_URL , submenu:[
      {
        'title': 'Hourly (Today)',
        'href': SUPERSET_URL_DASHBOARD_1,
        isExternalLink: true
      },
      {
        'title': 'Daily (Last 30 days)',
        'href': SUPERSET_URL_DASHBOARD_2,
        isExternalLink: true
      },
      {
        'title': 'Monthly (Last 3 months)',
        'href': SUPERSET_URL_DASHBOARD_3,
        isExternalLink: true
      }]}];
    const data = menuData.map((item: IMenuItemTop) => {
      if (item.submenu && item.submenu.length > 0) {
        const submenu = item.submenu.map((submenu: IMenuItemSubmenu) => {
          return { ...submenu, 'title': t(submenu.title) };
        });
        return { ...item, 'title': t(item.title), 'submenu': submenu };
      } else {
        return { ...item, 'title': t(item.title) };
      }
    });
    if (!TokenService.getUserType()) {
      const guestMenuData = clonedeep(data);
      guestMenuData.splice(guestMenuData.findIndex(a => a.href === '/settings'), 1);
      return { items: [...guestMenuData] };
    } else {
      return { items: [...data] };
    }
  }, [ t]);

  useEffect(() => {
    setUserType(TokenService.getUserType());
    setMenus(translateMenus());
  }, [translateMenus]);

  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);

  const loginGuest = useCallback(() => {
    const data = {
      access_token: 'tce_guest',
      refresh_token: 'tce_guest',
      user: {
        role: 'guest',
        user_id: 0,
        username: 'Guest'
      }
    };

    sessionStorage.setItem('isGuestLogout', 'false');
    TokenService.setUser(data);
    setIsAuthenticated(true);
  }, []);

  const accessGuest = useCallback(() => {
    if(location.pathname !== '/cameras'
    && !location.pathname.startsWith('/cameras/camera-details/')
    && location.pathname !== '/export'){
      return;
    }
    loginGuest();
  }, [location, loginGuest]);

  useEffect(() => {
    if (!loadingGuest) {
      if (guestUser) {
        if (sessionStorage.getItem('isGuestLogout') && sessionStorage.getItem('isGuestLogout') === 'true') {
          setIsAuthenticated(false);
          TokenService.removeUser();
          return;
        }
        if (!localStorage.getItem('user_login_info')){
          accessGuest();
        } else {
          setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
        }
      } else {
        setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
      }
    }
    if(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) === null){
      localStorage.setItem(`checkoBox_Video_Annotation_${userName}`, 'true');
    }
  }, [loadingGuest, guestUser, accessGuest, userName]);

  const getDiskFullDetails = useCallback(async () => {
    try {
      const { data: { data } } = await getHealthStatusDetails();
      if(data.status === 'failed') {
        setIsDiskFull(true);
      } else {
        setIsDiskFull(false);
      }
    } catch (err) {
      setIsDiskFull(false);
      console.error(err);
    }
  }, []);

  useEffect(() => {
    getDiskFullDetails();
  }, [getDiskFullDetails]);

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/login' && location.pathname !== '/cameras'
      && !location.pathname.startsWith('/cameras/camera-details/')
      && location.pathname !== '/export') {
      history.replace('/login');
    }
    if (localStorage.getItem(`${window.location.hostname}_isMenuOpen`) === null) {
      localStorage.setItem(`${window.location.hostname}_isMenuOpen`, 'true');
    }
  }, [isAuthenticated, location, history]);

  const getApp = () => {
    return (
      <>
        <MainMenuContainer
          content={menus}
          logoMark={logoMarkSvg}
          logoText={LogoTextAppNameEn}
          keepOpenText='Keep Open'
          autoHideText='Auto-Hide'
          defaultMenuOpen={false}
          canAlwaysPin
        />
        <MainContainer>
          {isDiskFull && <DiskFullNotification type='error' message={t('Disk capacity is almost full. Please perform clean-up.')} />}
          <TopBar
            accountOptionText={t('Account Options')}
            loggedInUser={TokenService.getUser()}
            logoutText={t('Logout')}
            currentUserText={t('CURRENT USER')}
            onLanguageToggle={onLanguageChange}
            hasLanguage
            hasLogout={false}
            userDrawerBespoke={<PasswordModal {...{boundingBoxes, setBoundingBoxes}} />}
            userDrawerFooter={versionInfo}
            hasUserDrawerMeta
            userDrawerMeta={deviceInfo}
            hasUserDrawerFooter
            copySuccessMessage={t('Copied!')}
            // selectedLanguageText={language}
          />
          <Role />

          <SecureRoute path='/cameras/camera-details/:streamName/:tab' exact component={CameraDetails} />
          {userType &&
            <>
              <SecureRoute path='/cameras/camera-configuration/:streamName' exact component={CameraConfiguration} />
              <SecureRoute path='/add-camera' exact component={AddCamera} />
              <SecureRoute path='/edit-camera' exact component={AddCamera} />
              <SecureRoute path='/settings/create-user' exact component={CreateEditUser} />
              <SecureRoute path='/settings/edit-user' exact component={CreateEditUser} />
              <SecureRoute path='/settings/cloud-settings' exact component={CloudUploadSettings} />
              <SecureRoute path='/settings/safie-settings' exact component={SafieIntegration} />
              <SecureRoute path='/settings/user-management' exact component={UserManagement} />
            </>}
          <SecureRoute exact path={['/', '/login']}>
            <Redirect to='/cameras' />
          </SecureRoute>
          <SecureRoute path='/cameras' exact component={Cameras} />
          <SecureRoute path='/export' exact component={Export} />
        </MainContainer>
      </>
    );
  };

  const getLoginRoute = () => {
    return (
      <>
        <Route render={() => <Login {...{guestUser, loginGuest}} />} />
      </>
    );
  };

  return (
    <Layout>
      {!loadingGuest ?
        !isAuthenticated ? getLoginRoute() : getApp()
        :
        <SpinnerBox>
          <Spinner size='large' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerBox>}
    </Layout>
  );
};

export default App;
