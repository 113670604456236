import React, { FC, ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { EDGE_API_BASE_URL, LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL, STATUS_ALERT_DETAILS } from '../constants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon, LineReducer, LineSetContext, LineUI, useModal, usePoll, useMediaModal, Spinner} from 'scorer-ui-kit';
import { IPointSet, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled, { css } from 'styled-components';
import MediaModal from './MediaModal';
import { getStatusCategory, getStatusColor } from 'utils';
import i18n from 'i18n';
import { IPoints } from 'interface';
import { cloneDeep } from 'lodash';
import AwaitingStreamEn from '../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../svg/img_awaiting_stream_jp.jpg';

const Container = styled.div<{ disabled: boolean }>`
  height: 304px;
  width: 460px;
  position: relative;
  object-fit: cover;
  box-shadow: 1px 1px #cccc;
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
  @media (max-width:1438px) and (min-width:1280px) {
    width:48.7%;
  }
`;

const ImageBox = styled.div<{ showPointer:boolean }>`
  width: 100%;
  position: relative;
  background: #ddd;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 1439px) {
    width: 460px;
  }
  height: 260px;
  min-width: 400px;
  border-radius: 3px 3px 0 0;
  > div {
    height: inherit;
    > img {
      height: inherit;
    }
  }
  ${({ showPointer }) => showPointer && css`
    cursor: pointer;
  `}
`;

const DetailsBox = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 40px;
  background: white;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
    box-shadow: 1px 1px #cccc;
  }
`;

const LabelText = styled.p`
  font-size: 16px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  margin: 0px;
`;

const LabelTextSmall = styled(LabelText)`
  font-size: 10px;
  color: #ffffff;
`;
const LabelTextMedium = styled(LabelText)`
  font-size: 12px;
  color: #ffffff;
`;

const StatusBar = styled.div<{ color: string }>`
  position: absolute;
  width: 100%;
  bottom: 40px;
  height: 4px;
  background: ${props => props.color};
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const LineBox = styled.div`
  width: 40px;
  height: 100%;
  padding: 11px;
  border-left: 1px solid #cccc;
  cursor: pointer;
`;

const WarningBox = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height:46px;
  bottom: 40px;
  background-color: ${({ color }) => color};
  opacity: 0.65;
  cursor: pointer;
  @media (max-width:1438px) and (min-width:1280px) {
    min-width: 400px;
  }
`;

const StatusText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  color: white;
`;

const StatusIconBox = styled.div`
  padding: 10px;
  width: 33px;
  height: 42px;
`;

const CameraTextBox = styled.div`
  flex: 1;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor? backgroundColor: '#a6a6a6'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  @media (max-width:1438px) and (min-width:1280px) {
    height: 253px;
  }
`;

const FormatterSvg = styled.div`
  @media (max-width:1438px) and (min-width:1280px) {
    svg {
      width: 90%;
    }
  }
  display: flex;
    justify-content: space-around;
`;

const ImageNone = styled.img`
  height: 300px;
  min-width: 400px;
  object-fit: cover;
`;

interface ICard {
  options?: LineUIOptions;
  data: any;
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

interface ILinePoints {
  points: IPoints,
  changeLineStyle: boolean
}

const Card: FC<ICard> = ({ options = {}, data }) => {

  const [showLine, setShowLine] = useState<boolean>(true);
  const [state, dispatch] = useReducer(LineReducer, []);
  const [image, setImage] = useState('');
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [noImage, setNoImage] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const { createModal, setModalOpen } = useModal();
  const { push } = useHistory();
  const { isMediaUrlValid } = useMediaModal();
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';

  useEffect(() => {
    const cameraName = localStorage.getItem('showLine_'+ data?.camera_name.toString());
    Object.entries(localStorage).map((i: string[]) => {
      if(i[0] === 'showLine_'+ data?.camera_name.toString()) {
        setShowLine(cameraName === 'true');
      }
      return i;
    });
  },[data]);

  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${EDGE_API_BASE_URL}/stacks/${data.stream_name}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        if (res.data.byteLength > 5) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          const isImageValid = await isMediaUrlValid(imgBase64, 'img');
          if (isImageValid === true) {
            const img = new Image();
            img.src = imgBase64;
            setImage(imgBase64);
            setImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
            setNoImage(false);
            setImageLoading(false);
          }
        } else {
          setImage('');
          setNoImage(true);
          setImageLoading(false);
        }
      } else {
        setNoImage(true);
        setImageLoading(false);
        setImageLoading(false);
      }
    } catch (err) {
      setNoImage(true);
      console.error(err);
    }
  }, [data, isMediaUrlValid]);

  usePoll(async () => {
    await getCameraImage();
  }, (LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL * 1000));

  const getLinePoints = useCallback((points: IPoints): ILinePoints => {
    let changeLineStyle = false;
    const boundaryOffset = Math.round(imageDetails.x * 2.5 / 100); // 48

    if (image === '') {
      return {points, changeLineStyle};
    }

    // checks if any point goes outside the boundary of image and trims those points
    if (
      (points.start_x < boundaryOffset || points.start_x > (imageDetails.x - boundaryOffset)) ||
      (points.start_y < boundaryOffset || points.start_y > (imageDetails.y - boundaryOffset)) ||
      (points.end_x < boundaryOffset || points.end_x > (imageDetails.x - boundaryOffset)) ||
      (points.end_y < boundaryOffset || points.end_y > (imageDetails.y - boundaryOffset))
    ) {
      changeLineStyle = true;
    }
    points.start_x = points.start_x < boundaryOffset ? boundaryOffset : points.start_x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points.start_x;
    points.start_y = points.start_y < boundaryOffset ? boundaryOffset : points.start_y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points.start_y;
    points.end_x = points.end_x < boundaryOffset ? boundaryOffset : points.end_x > (imageDetails.x - boundaryOffset) ? (imageDetails.x - boundaryOffset) : points.end_x;
    points.end_y = points.end_y < boundaryOffset ? boundaryOffset : points.end_y > (imageDetails.y - boundaryOffset) ? (imageDetails.y - boundaryOffset) : points.end_y;

    return {points, changeLineStyle};
  }, [image, imageDetails]);

  useEffect(() => {
    let state: IPointSet[] = [];
    if (data.line_configuration && data.line_configuration.length > 0) {
      state = data.line_configuration.map(({ points }: any) => {
        const point = cloneDeep(points);
        const { points: updatedPoint, changeLineStyle } = getLinePoints(point);
        
        return ({
          points: [
            {
              x: updatedPoint.start_x,
              y: updatedPoint.start_y
            },
            {
              x: updatedPoint.end_x,
              y: updatedPoint.end_y
            }
          ],
          readOnly: true,
          showSmallDirectionMark: true,
          styling: changeLineStyle ? 'danger' : 'primary'
        });
      });
    }
    dispatch({
      type: 'LOAD',
      state: showLine ? state : []
    });
  }, [showLine, data, getLinePoints]);

  const onClickToShowLine = useCallback(() => {
    if (!noImage) {
      const showLineStatus = !showLine;
      localStorage.setItem('showLine_' + data?.camera_name, showLineStatus.toString());
      setShowLine(prev => !prev);
    } else {
      return false;
    }
  }, [noImage, showLine, data]);

  const goToCameraDetail = useCallback(() => {
    push(`/cameras/camera-details/${data.stream_name}/overview`);
  }, [data.stream_name, push]);

  const openMediaModel = useCallback((streamName: string) => { 
    createModal({
      isCloseEnable: true,
      width: '60%',
      padding: false,
      closeText: t('CLOSE'),
      dismissCallback: () => { setModalOpen(false); },
      customComponent: (<MediaModal {...{ state, dispatch, image, options, streamName }} bufferTime={(data?.camera_type === 'RTSP' ? data?.video_configuration?.buffer_time : LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL) * 1000} />)
    });
  }, [data, state, dispatch, image, options, createModal, setModalOpen, t]);

  const getIcon = (statusCategory: string) => {
    if(statusCategory === 'warning') {
      return 'BigWarning';
    } else if(statusCategory === 'Error') {
      return 'Critical';
    } else {
      return 'Warning';
    }
  };

  const getBannerDetails = useCallback((): ReactElement | null => {
    if (data.status.status_category && data.status.status_code) {
      if (getStatusCategory(data.status.status_code) === 'Ok') {
        return null;
      } else {
        const message = STATUS_ALERT_DETAILS[getStatusCategory(data.status.status_code)];
        return (
          <WarningBox color={getStatusColor(data.status.status_category)} onClick={goToCameraDetail}>
            <StatusIconBox>
              <Icon size={18} color='inverse' icon={getIcon(data?.status.status_category)} />
            </StatusIconBox>
            <StatusText>
              <LabelTextMedium>{t(message.title)}</LabelTextMedium>
              <LabelTextSmall>{t(message.message)}</LabelTextSmall>
            </StatusText>
          </WarningBox>
        );
      }
    }
    return null;
  }, [data, t, goToCameraDetail]);

  return (
    <Container disabled={data.status_code === 20100}>
      <ImageBox
        showPointer={!noImage}
        onClick={() => { (!noImage) && openMediaModel(data.stream_name); }}
      >
        {noImage ?
          <FormatterSvg>
            {lang==='ja' ?
              <ImageNone src={AwaitingStreamJp} /> : <ImageNone src={AwaitingStreamEn} />}
          </FormatterSvg>
          : 
          <>
            {imageLoading ?
              <SpinnerContainer>
                <Spinner size='large' styling='primary' />
              </SpinnerContainer> :
              <LineSetContext.Provider value={{ state, dispatch }}>
                <LineUI 
                  options={{...options, boundaryOffset: Math.round(imageDetails.x * 2.5 / 100)}} 
                  src={image} 
                  hasClickSensingBorder={false}
                />
              </LineSetContext.Provider>}
          </>}
      </ImageBox>
      {getBannerDetails()}
      <StatusBar color={(data.status.status_category === 'Running' ? '#87d58f' : data.status.status_category === 'Error' ? '#ef7878' : '#96acbc')} />
      <DetailsBox>
        <CameraTextBox onClick={goToCameraDetail}>
          <LabelText title={t(data?.camera_name)}>{t(data?.camera_name)}</LabelText>
        </CameraTextBox>
        <IconBox>
          <LineBox onClick={onClickToShowLine} title={t('Click')}>
            <Icon size={16} color={showLine ? 'primary' : 'dimmed'} icon='FeatureLineUi' />
          </LineBox>
        </IconBox>

      </DetailsBox>
    </Container>
  );
};

export default Card;