import { IStatusAlertDetails } from 'interface';

const HOST = window.location.hostname;
export const SERVER_URL = window.location.protocol + '//' + HOST;
export const STREAM_TYPE = 'Traffic';
export const VIDEO_SOURCE_TYPE = 'RTP';
export const ALERT_FETCH_INTERVAL = 10; // seconds
export const EXPIRY_WINDOW = 10;
export const ACTIVE_ALERT_MINUTES = 5;
export const ACTIVE_ALERT_HOURS = 0;
export const ACTIVE_ALERT_DAYS = 0;

export const BASE_API_URL = '/api/v1/';
export const EDGE_API_BASE_URL = '/edge/api/v1/';
export const SUPERSET_URL = '/viz/superset/';
export const SUPERSET_URL_DASHBOARD_1 = '/viz/superset/dashboard/1/';
export const SUPERSET_URL_DASHBOARD_2 = '/viz/superset/dashboard/2/';
export const SUPERSET_URL_DASHBOARD_3 = '/viz/superset/dashboard/3/';

export const AnalysisGroup = {
  REAL_TIME: 1,
  GROUP_A: 2,
  GROUP_B: 3,
  GROUP_C: 4,
  GROUP_D: 5, 
  NO_ANALYSIS: 6
};

export const ENABLE_ANALYSIS_GROUP = process.env.REACT_APP_ENABLE_ANALYSIS_GROUP === 'true';
export const ENABLE_ALERTS = process.env.REACT_APP_ENABLE_ALERTS === 'true';
export const ENABLE_PTZ = process.env.REACT_APP_ENABLE_PTZ === 'true';
export const SHOW_DETECTION_OBJECT_TYPE = process.env.REACT_APP_SHOW_DETECTION_OBJECT_TYPE === 'true';
export const LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL = 30;
export const WEBRTC_BASE_URL = 'ws://' + HOST;
export const DATE_FORMAT = 'yyyy/MM/dd HH:mm';
export const DATE_FORMATT_WITH_HYPHEN = 'yyyy/MM/dd - HH:mm';
export const DATE_FORMAT_WITH_SEC = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT_WITHOUT_SEC = 'yyyy-MM-dd HH:mm';
export const DATE_FORMAT_WITHOUT_TIME = 'yyyy-MM-dd';
export const DATE_FORMAT2_WITH_SEC = 'yyyy/MM/dd HH:mm:ss';
export const DATE_FORMAT_FILE_TAB_API = 'yyyy-MM-dd HH:mm:ss';
export const TIMEZONE = 'Asia/Tokyo';
export enum EXPORT_STATUS_MAP {
  initialize= 0,
  inProcess,
  success,
  failed
}
export const STREAM_STATUS_GET_INTERVAL = 5; //seconds
export const CAMERA_DETAILS_STATUS_GET_INTERVAL = 10; //seconds

export const ENABLE_CONGESTION_STOP_DETECTION = process.env.REACT_APP_ENABLE_CONGESTION_STOP_DETECTION === 'true';
export const ENABLE_TRAFFIC_COUNTER = process.env.REACT_APP_ENABLE_TRAFFIC_COUNTER === 'true';
export const MIN_LINE_POINTS = 3;
export const MAX_LINE_POINTS = 12;
export const DEFAULT_CONGESTION_THRESHOLD = 30;
export const DEFAULT_STOP_THRESHOLD = 1;
export const DEFAULT_WIDTH_THRESHOLD = 1;

export const PIXEL_ASPECT_RATIO = [
  {'name': 'auto', 'value': 'auto'},
  {'name': '1:1', 'value': '1:1'},
  {'name': '4:3', 'value': '4:3'}
];

export const RTP_H264_PAYLOAD_TYPE = 96;
export const RTP_MPEG2_TTS_PAYLOAD_TYPE = 103;
export const RTP_MPEG2_PS_PAYLOAD_TYPE = 96;
export const RTP_MPEG2_TS_PAYLOAD_TYPE = 33;

export const RTP_TRANSPORT_ENCAPSULATION_OPTION_LIST = [
  {'name': 'MPEG-2 PS', 'value': 'MPEG2PS'},
  {'name': 'MPEG-2 TTS', 'value': 'MPEG2TTS'},
  {'name': 'MPEG-2 TS', 'value': 'MPEG2TS'},
  {'name': 'H-264', 'value': 'H264'}
];

export const VIDEO_CODEC_TYPE_H264 = [
  {'name': 'H-264', 'value': 'H264'}
];

export const VIDEO_CODEC_TYPE_MPEG2PS= [
  {'name': 'MPEG-2', 'value': 'MPEG2'}
];

export const VIDEO_FPS_OPTION_LIST = [
  {'name': 'auto', 'value': 'auto'},
  {'name': '1fps', 'value': '1/1'},
  {'name': '5fps', 'value': '5/1'},
  {'name': '7fps', 'value': '7/1'},
  {'name': '10fps', 'value': '10/1'},
  {'name': '12fps', 'value': '12/1'},
  {'name': '15fps', 'value': '15/1'},
  {'name': '20fps', 'value': '20/1'},
  {'name': '25fps', 'value': '25/1'},
  {'name': '30fps', 'value': '30/1'},
  {'name': '1/5fps', 'value': '1/5'},
  {'name': '1/15fps', 'value': '1/15'},
  {'name': '1/30fps', 'value': '1/30'},
  {'name': '1/60fps', 'value': '1/60'},
  {'name': '1/180fps', 'value': '1/180'},
  {'name': '1/600fps', 'value': '1/600'}
];

export enum TransportEncapsulation {
  H264 = 'H264',
  MPEG2_PS = 'MPEG2PS',
  MPEG2_TS = 'MPEG2TS',
  MPEG2_TTS = 'MPEG2TTS'
}

export const DEFAULT_CAMERA_FORM = {
  display_name: '',
  video_payload_type: RTP_H264_PAYLOAD_TYPE,
  video_multicast_address: '',
  video_port_rtp: '',
  video_port_rtcp: '',
  cycle_time: 10,
  analysis_group_id: 1,
  transport_encapsulation: 'MPEG2PS',
  video_fps: '10/1',
  video_codec: 'MPEG-2',
  road_name: '',
  location_info: '',
  pixel_aspect_ratio: 'auto',
  gpu_affinity: 'system',
  gpu_device: '',
  observation_point_code: '',
  access_and_preset_info: {
    camera_id: '',
    camera_ip: '',
    camera_port: '',
    ptz_preset: ''
  },
  enabled: true,
  type: '',
  video_source_type: '',
  enable_video_multicast: true,
  enable_video_rtcp_muxed: true
};

export const CAMERA_TYPE = [
  { name: 'Safie Camera', value: 'SAFIE' },
  { name: 'IP Camera (RTSP)', value: 'RTSP' },
  { name: 'Streaming Server (RTMP)', value: 'RTMP' },
  { name: 'Webcam (USB Video Class)', value: 'USB' }
];

export const DEFAULT_SAFIE_CONNECTION_DATA = {
  'type': 'safie_settings',
  'enabled': true,
  'configuration_time': '',
  'refresh_token': '',
  'client_id': '',
  'client_secret': '',
  'access_token_status': ''
};

// intial line options
export const initialLineOptions = [
  'None',
  'Line1', 'Line2', 'Line3', 'Line4', 'Line5', 'Line6',
  'Line7', 'Line8', 'Line9', 'Line10', 'Line11', 'Line12',
  'Line13', 'Line14', 'Line15', 'Line16', 'Line17', 'Line18',
  'Line19', 'Line20',
];

export const ENABLE_VIDEO_RECORDER = true;
export const DATE_TIME_FORMAT = 'yyyy-MM-dd - HH:mm';

export const CAMERA_MANUFACTURER_LIST = [
  { name: 'Hikvision', value: 'Hikvision' },
  { name: 'Axis', value: 'Axis' },
  { name: 'Other', value: 'Other' }
];

export const MAXIMUM_PACKET_DELAY_LIST = [
  { name: 'auto', value: 'auto' },
  { name: 'custom', value: 'custom' },
];

export const CAMERA_MODEL_LIST = [
  { name: 'RD-CI292P', value: 'RD-CI292P' },
  { name: 'RD-CI510', value: 'RD-CI510' },
  { name: 'DS-2CD2025FWD-I', value: 'DS-2CD2025FWD-I' },
  { name: 'DS-2CD2085FWD-I', value: 'DS-2CD2085FWD-I' },
  { name: 'DS-2CD2442FWD-IW', value: 'DS-2CD2442FWD-IW' },
];

export const STATUS_ALERT_DETAILS:IStatusAlertDetails  = {
  '20000': {
    type: 'Error',
    title: 'Camera Error',
    message: 'Camera removed.'
  },
  '20001': {
    type: 'Error',
    title: 'Camera Error',
    message: 'Can not retrieve frames from camera.'
  },
  '20002': {
    type: 'Error',
    title: 'Analysis Error',
    message: 'Analysis is currently not running.'
  },
  '20003': {
    type: 'Error',
    title: 'Invalid Feed (Color Bar)',
    message: 'Please restore feed to enable analysis.'
  },
  '20004': {
    type: 'Error',
    title: 'Invalid Feed (Noise)',
    message: 'Please restore feed to enable analysis.'
  },
  '20005': {
    type: 'Error',
    title: 'Invalid Feed (Static Video)',
    message: 'Please restore feed to enable analysis.'
  },
  '20006': {
    type: 'Error',
    title: 'No Feed',
    message: 'Please restore feed to enable analysis.'
  },
  '20007': {
    type: 'Error',
    title: 'Video Status Error',
    message: 'Video status could not be fetched.'
  },
  '30001': {
    type: 'Warning',
    title: 'Loading ...',
    message: 'Please wait until the video is coming.'
  },
  '30002': {
    type: 'Warning',
    title: 'Analysis not running',
    message: 'Please enable analysis in configuration to start.'
  },
  '30003': {
    type: 'Warning',
    title: 'Configuration Pending',
    message: 'Please complete configuration to start analysis.'
  },
  '40000': {
    type: 'Disabled',
    title: 'Camera Disabled',
    message: 'Please enable the camera to receive the video stream.'
  },
  Unknown: {
    type: 'Error',
    title: 'Unknown Error',
    message: 'Please contact support.'
  }
};

export const USB_STATUS_ALERT_DETAILS:IStatusAlertDetails  = {
  '30501': {
    type: 'Warning',
    title: 'USB Warning',
    message: 'The webcam does not support either MJPG or I420.'
  },
  '30502': {
    type: 'Warning',
    title: 'USB Warning',
    message: 'The webcam does not support either FHD (1920x1080) or HD (1080x720)'
  },
  '30503': {
    type: 'Warning',
    title: 'Webcam Warning',
    message: 'The webcam does not support a sufficient framerate for the video analysis (min{X} 10fps)'
  },
  '30600': {
    type: 'Warning',
    title: 'USB Warning',
    message: 'Could not check if attached webcam is suitable.'
  },
  '30700': {
    type: 'Error',
    title: 'USB Error',
    message: 'USB device is not present.'
  },
  Unknown: {
    type: 'Warning',
    title: 'USB Warning',
    message: 'Webcam is in unexpected status.'
  }
};

export const AlertIcon: {[key: string]: string} = {
  Error: 'Critical',
  Warning: 'BigWarning',
  Disabled: 'Warning',
};

export const RTMP_IP_URL = 'rtmp://{X}:1935/live';
export const RTMP_MDNS_URL = 'rtmp://{X}.local:1935/live';
export const RTMP_EXTERNAL_EXAMPLE_URL = 'rtmp://server.example.com/';

export const CAMERA_WEB_UI_URL= 'http://{X}/doc/page/login.asp';
export const CAMERA_WEB_UI_URL_AXIS = 'http://{X}/#view';
export const MANUFACTURER_HIKVISION = 'Hikvision';
export const MANUFACTURER_AXIS = 'Axis';
