import { ITrafficCounterData } from 'interface';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  ButtonWithLoading,
  Content,
  Label,
  PageHeader,
  Spinner,
  Tab,
  TabContent,
  TabList,
  Tabs,
  useModal,
  useNotification,
} from 'scorer-ui-kit';
import { getConfigDetails, getSingleCameraDetails, setConfigDetails } from 'services/apiConfig';
import { SpinnerContainer } from 'style';
import styled from 'styled-components';
import { Divider } from 'Styles';
import { compareChanges } from 'utils';
import TrafficCounterTab from './TrafficCounterTab';


const Header = styled.div`
  width: 100%;
  max-width: 940px !important;
  height: 108px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  line-height: 1.2;
  > div:first-child > div > a {
    max-width: 750px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    bottom: 34.5px;
    top: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CancelButton = styled(Button)`
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
  :disabled {
    background: #e9f0f6;
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  gap: 0 8px;
  justify-content: flex-end;

  & > #NoButton:focus {
    outline: 2px solid #838383;
  }

  & > #YesButton:focus {
    outline: 2px solid #838383;
  }
`;

const ModalMessage = styled(Label)`
  margin-bottom: 20px;
`;

const ModalContainer = styled.div`
  max-width: 580px;
  width: 100%;
  padding: 30px 40px;
`;

const ButtonContainer = styled.div`
  margin-top: -14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px 0;
  & > button {
    min-width: 155px;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const UnsavedChangesText = styled.div`
  margin-top: 6px;
  width: 155px;
  font-size: 12px;
  text-align: center;
  border-radius: 3px;
  border: solid 1px #e4edf4;
`;

const AreaContainer = styled.span`
  height: 15px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
  	&:hover {
      border-bottom: 1px solid #767676;;
      box-sizing: border-box;
      width: fit-content;
    }
`;

const MainContent = styled.div`
  margin-top: -5px;
  max-width: 940px !important;
`;

const LabelFormatter = styled(Label)`
  font-size: 22px;
`;

interface IParams {
  streamName: string;
}

const initialTrafficCounterData = {
  stream_type: '',
  camera_name: '',
  analysis_enabled: false,
  periodic_snapshot_interval: 60,
  lines: []
};

interface ILine {
  name: string,
  idx: number,
  points: {
    end_x: number,
    end_y: number,
    start_y: number,
    start_x: number,
  }
}

interface IUsbDeviceStatus {
  status_code: number
}

interface ILineConfiguration {
  lines: ILine[],
  analysis_enabled: boolean
}

export interface ICameraDetails {
  camera_name: string,
  stream_name: string,
  notes: string,
  line_configuration: ILineConfiguration[],
  usb_device_status?: IUsbDeviceStatus,
  status?: {
    status_code: number,
    status_category: 'Running' | 'Error' | 'Warning' | 'Disabled'
  },
  latest_activity_time?: number
}

export const initialCameraDetails: ICameraDetails = {
  camera_name: 'Camera Name',
  stream_name: '',
  notes: '-',
  line_configuration: [],
};

const CameraConfiguration: React.FC = React.memo(() => {
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [unSavedChanges, setUnsavedCanges] = useState<number>(0);
  const [trafficCounterData, setTrafficCounterData] = useState<ITrafficCounterData>(initialTrafficCounterData);
  const fetchedLineDetails = useRef<ITrafficCounterData>(initialTrafficCounterData);
  const [updatedTrafficCounterData, setUpdatedTrafficCounterData] = useState<ITrafficCounterData>(initialTrafficCounterData);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const { streamName } = useParams<IParams>();
  const history = useHistory();
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);
  const [selectedTab, setSelectedTab] = useState<string>('trafficCounter');
  const {isModalOpen, createModal, setModalOpen} = useModal();
  const [cameraDisconnected, setCameraDisconnected] = useState<number>();
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>(initialCameraDetails);
  const { goBack } = useHistory();

  const getDetails = useCallback(async () => {
    setIsDataFetched(false);
    try {
      const res = await getSingleCameraDetails(streamName);
      setCameraDetails(res.data.data);
      if(res?.data?.data?.usb_device_status?.status_code === 30700){
        setCameraDisconnected(res.data.data.usb_device_status.status_code);
      }
    } catch (error) {
      sendNotificationRef.current({ 
        type: 'error',
        message: tRef.current('Failed to load camera details.')
      });
      console.error(error);
    }
  }, [streamName]);

  useEffect(() => {
    if(streamName){
      getDetails();
    }
    return () => {};
  }, [getDetails, streamName]);

  const getTrafficDetails = useCallback(async () => {
    setIsDataFetched(false);
    try {
      const res = await getConfigDetails(streamName);
      if (res.data.status_code === 0) {
        setUnsavedCanges(0);
        setUpdatedTrafficCounterData(res.data.data);
        fetchedLineDetails.current = res.data.data;
        setTrafficCounterData(res.data.data);
      } else {
        sendNotificationRef.current({
          type: 'error',
          message: tRef.current('Failed to fetch settings')
        });
      }
      setIsDataFetched(true);
    } catch (error) {
      sendNotificationRef.current({
        type: 'error',
        message: tRef.current('Failed to fetch settings')
      });
      setIsDataFetched(true);
      console.error(error);
    }
  }, [streamName]);

  useEffect(() => {
    getTrafficDetails();
    return () => {};
  }, [getTrafficDetails]);

  const getUpdatedTrafficCounterData = useCallback((data: ITrafficCounterData) => {
    setUpdatedTrafficCounterData(prev => {
      return { ...prev, ...data };
    });
  }, []);

  // calculates unsaved changes
  useEffect(() => {
    let changesCount = 0;
    changesCount += compareChanges(updatedTrafficCounterData, fetchedLineDetails.current, 'analysis_enabled');
    changesCount += compareChanges(updatedTrafficCounterData, fetchedLineDetails.current, 'periodic_snapshot_interval');
    changesCount += compareChanges(updatedTrafficCounterData, fetchedLineDetails.current, 'lines');
    setUnsavedCanges(changesCount);
    return () => {};
  }, [fetchedLineDetails, updatedTrafficCounterData]);

  const handleTabChange = useCallback((tabName: string) => {
    setSelectedTab(tabName);
    if (tabName === 'trafficCounter') {
      setTrafficCounterData({ ...trafficCounterData, ...updatedTrafficCounterData });
    } else {
      // set previous details of advanced tab
    }
  }, [trafficCounterData, updatedTrafficCounterData]);

  const isValidData = useCallback((data: ITrafficCounterData) => {
    const { periodic_snapshot_interval, lines } = data;

    let idx = -1, type = '';
    const isEveryLineValid = lines.every((item, index) => {
      if (!item.name) {
        type = 'name';
        idx = index;
        return false;
      } else if (item.direction_type === 'Manual' && !item.fwd_direction_label) {
        type = 'fwdLabel';
        idx = index;
        return false;
      } else if (item.direction_type === 'Manual' && !item.back_direction_label) {
        type = 'backLabel';
        idx = index;
        return false;
      } 
      return true;
    });

    if (!periodic_snapshot_interval) {
      sendNotification({
        type: 'error',
        message: t('Please provide periodic snapshot interval.')
      });
      return false;
    } else if (!isEveryLineValid) {
      if (type === 'name') {
        sendNotification({
          type: 'error',
          message: t('Please enter line reference for line number {X}.').replace('{X}', `${idx + 1}`)
        });
      } else if (type === 'backLabel') {
        sendNotification({
          type: 'error',
          message: t('Please enter backward label for line number {X}.').replace('{X}', `${idx + 1}`)
        });
      } else if (type === 'fwdLabel') {
        sendNotification({
          type: 'error',
          message: t('Please enter forward label for line number {X}.').replace('{X}', `${idx + 1}`)
        });
      } 
      return false;
    }
    return true;
  }, [t, sendNotification]);

  const saveChanges = useCallback(async (data: ITrafficCounterData) => {
    setIsReadOnly(true);
    try {
      const res = await setConfigDetails(streamName, data);
      if (res.data.status_code === 0) {
        setUpdatedTrafficCounterData(data);
        fetchedLineDetails.current = data;
        setTrafficCounterData(JSON.parse(JSON.stringify(data)));
        sendNotificationRef.current({
          type: 'success',
          message: t('Settings saved successfully')
        });
        goBack();
      } else {
        sendNotificationRef.current({
          type: 'error',
          message: t('Failed to save settings')
        });
      }
      setIsReadOnly(false);
      setSaveLoading(false);
    } catch (error) {
      setIsReadOnly(false);
      console.error(error);
      setSaveLoading(false);
      sendNotificationRef.current({
        type: 'error',
        message: t('Failed to save settings')
      });
    }
  }, [goBack, streamName, t]);

  const onSave = useCallback(() => {
    setSaveLoading(true);
    if (isValidData(updatedTrafficCounterData)) {
      
      saveChanges({ ...trafficCounterData, ...updatedTrafficCounterData });
    } else {
      setSaveLoading(false);
    }
  }, [trafficCounterData, updatedTrafficCounterData, saveChanges, isValidData]);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
    history.push(`/cameras/camera-details/${streamName}/overview`);
  }, [history, streamName, setModalOpen]);
  
  const arrowEvent = useCallback((e) => {
    if (isModalOpen) {
      const validKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!validKeys.includes(e.key)) return;
      e.preventDefault();

      if (e.key === 'ArrowLeft') {
        document.getElementById('NoButton')?.focus();
      } else if (e.key === 'ArrowRight') {
        document.getElementById('YesButton')?.focus();
      } else if (e.key === 'Escape') {
        document.getElementById('NoButton')?.click();
      } else if (e.key === 'Tab') {
        if (document.activeElement?.id === 'NoButton') {
          document.getElementById('YesButton')?.focus();
          return;
        }
        document.getElementById('NoButton')?.focus();
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('keydown', arrowEvent);
    if (isModalOpen) {
      document.getElementById('NoButton')?.focus();
    }
    return () => {
      window.removeEventListener('keydown', arrowEvent);
    };
    return () => {};
  }, [isModalOpen, arrowEvent]);
  
  // // Confirm Modal
  const getConfirmationModal = useCallback(() => {
    return (
      <ModalContainer>
        <ModalMessage htmlFor='account_option' labelText={t('Are you sure you want to cancel the changes?')} />
        <ModalButtonContainer>
          <CancelButton
            id='NoButton'
            design='secondary'
            onClick={() => setModalOpen(false)}
          >
            {t('No')}
          </CancelButton>
          <Button
            id='YesButton'
            design='primary'
            onClick={handleCancel}
          >
            {t('Yes')}
          </Button>
        </ModalButtonContainer>
      </ModalContainer>
    );
  }, [t, setModalOpen, handleCancel]);

  const onCancel = useCallback(() => {
    if(unSavedChanges === 0){
      goBack();
    } else {
      const confirmModal: ReactElement = getConfirmationModal();
      createModal({
        isCloseEnable: false,
        width: '480px',
        padding: false,
        customComponent: confirmModal,
      });
    }
  }, [unSavedChanges, goBack, getConfirmationModal, createModal]);

  if (!isDataFetched) {
    return (
      <SpinnerContainer>
        <Spinner size='large' styling='primary' />
        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
      </SpinnerContainer>
    );
  }

  if(cameraDisconnected === 30700) {
    return (
      <SpinnerContainer>
        <LabelFormatter htmlFor='' labelText={t('USB device is not present, so no access to this page.')} />
      </SpinnerContainer>
    );
  }

  const goToPreviousPage = () => {
    goBack();
  };

  return (
    <Content>
      <Header>
        <PageHeaderComponent>
          <AreaContainer onClick={goToPreviousPage}>{!trafficCounterData.camera_name ? '-' : trafficCounterData.camera_name}</AreaContainer>
          <PageHeader
            title={t('Configuration')}
            icon='Analyse'
            updateDocTitle={false}
          />
        </PageHeaderComponent>  
        {selectedTab === 'trafficCounter' && 
          <ButtonContainer>
            <CancelButton
              design='secondary'
              size='small'
              onClick={onCancel}
            >
              {t('Cancel')}
            </CancelButton>
            <ButtonWithLoading loading={saveLoading} disabled={unSavedChanges === 0} design='primary' size='small' onClick={onSave}>
              {t('Save Changes')}
            </ButtonWithLoading>
            {unSavedChanges > 0 && <UnsavedChangesText>{t('{X} unsaved changes').replace('{X}', (unSavedChanges+ ''))}</UnsavedChangesText>}
          </ButtonContainer>}
      </Header>
      <MainContent>
        <Tabs>
          <TabList defaultTabId='trafficCounter'>
            <Tab tabFor='trafficCounter' onClick={() => handleTabChange('trafficCounter')}>{t('Traffic Counter')}</Tab>
          </TabList>
          <Divider />
          <TabContent tabId='trafficCounter'>
            <TrafficCounterTab {...{ streamName, isReadOnly, trafficCounterData, getUpdatedTrafficCounterData, cameraDetails }} />
          </TabContent>
        </Tabs>
      </MainContent>
    </Content>
  );
});

export default CameraConfiguration;
