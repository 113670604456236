import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, useModal, Switch } from 'scorer-ui-kit';
import UpdatePassword from 'pages/UpdatePassword';
import styled from 'styled-components';
import TokenService from 'services/tokenService';
import AuthService from 'services/authService';
import i18n from 'i18n';

const AccountOptionBox = styled.div`
  padding: 20px 20px 15px;
  border-bottom: hsla(0,0%,93.3%,1.000) 1px solid;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  color: hsla(0,0%,34.1%,0.749);
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;

const AccountOption = styled(Label)`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: hsla(0,0%,34.5%,0.902);
  margin-bottom: 0;
  margin: -10px 0 0 0;
`;

const PasswordReset = styled(Label)`
  color: rgba(88, 88, 88, 0.65);
  font-weight: 300;
  cursor: pointer;
`;

const PasswordResetGuest = styled(Label)`
  color: rgba(88, 88, 88, 0.65);
  font-weight: 300;
  cursor: pointer;
  margin-top: 34px;
`;

const PasswordText = styled(Label)`
  margin: 18px 0 -10px 0px;
  color: rgba(88, 88, 88, 0.65);
  font-weight: 300;
  cursor: pointer;
`;

const VideoText = styled(Label)`
  margin: 24px 28px 12px 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.35px;
  color: rgba(88, 88, 88, 0.9);
`;

const VideoTextGuest = styled(Label)`
  margin: 0px 28px 12px 0px;
  min-width: 150px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.35px;
  color: rgba(88, 88, 88, 0.9);
`;

const LogOut = styled.div`
  padding: 20px 20px 15px;
`;

const SwitchBox = styled.div<{ maxWidth: string }>`
  display: flex;
  min-width: ${({ maxWidth }) => maxWidth};
  margin-left: 10px;
  gap:20px;
`;

const SwitchBoxGuest = styled.div<{ maxWidth: string }>`
  display: flex;
  min-width: ${({ maxWidth }) => maxWidth};
  margin-left: -9px;
  gap:20px;
  margin-top: 18px;
`;

const SwitchText = styled(Label)`
  margin-left: 10px;
  line-height: 22px;
  text-align: left;
  font-size: 14px;
  margin-bottom: 0;
  span{
    margin-bottom: 0;
  }
`;

const TextForVideo = styled.div`
  margin: 20px 18px 0 20px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #8b9196;
`;

const TextForVideoGuest = styled.div`
  margin: 20px 18px 0 0px;
  font-size: 12px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #8b9196;
`;

const Border = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #eee;
`;

const BorderGuest = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid #eee;
  margin-top: -70px;
`;

interface IProps {
  boundingBoxes: any;
  setBoundingBoxes: any;
}

const PasswordModal: FC<IProps> = ({boundingBoxes, setBoundingBoxes}) => {

  const { createModal, setModalOpen } = useModal();
  const { t } = useTranslation(['CommonDict']);
  const userName = TokenService.getUser();

  const onCheckboxChange = useCallback(()=>{
    setBoundingBoxes((prev:boolean) => !prev);
    localStorage.setItem(`checkoBox_Video_Annotation_${userName}`, (!boundingBoxes).toString());
  }, [setBoundingBoxes, userName, boundingBoxes]);

  const updateModel = useCallback(() => {
    createModal({
      isCloseEnable: true,
      width: '460px',
      padding: true,
      closeText: t('CLOSE'),
      dismissCallback: () => { setModalOpen(false); },
      customComponent: <UpdatePassword />,
    });
  }, [createModal, setModalOpen, t]);

  const guestLogout = useCallback(() => {
    sessionStorage.setItem('isGuestLogout', 'true');
    TokenService.removeUser();
    window.location.href = '/login';
  }, []);

  if (TokenService.getUserTypeGuest()) {
    return (
      <div>
        <LogOut>
          <VideoTextGuest htmlFor='' labelText={t('Video Annotations')} />
          <SwitchBoxGuest maxWidth={i18n.language === 'ja' ? '126px' : '163px'}>
            <SwitchText htmlFor='' labelText={`${boundingBoxes ? t('Enabled') : t('Disabled')}`} />
            <Switch
              checked={boundingBoxes}
              leftTheme='off'
              onChangeCallback={onCheckboxChange}
              rightTheme='on'
              state='default'
            />
          </SwitchBoxGuest>
          <TextForVideoGuest>
            {t('Only for streams with this feature. Other streams will display as normal.')}
          </TextForVideoGuest>
          <div onClick={guestLogout}>
            <PasswordResetGuest htmlFor='logout' labelText={t('Logout')} />
          </div>
        </LogOut>
        <BorderGuest />
      </div>
    );
  }

  return (
    <>
      <AccountOptionBox>
        <AccountOption htmlFor='account_option' labelText={t('Account Options')} />
        <div onClick={() => { updateModel(); }}>
          <PasswordText htmlFor='Update_Password' labelText={t('Update Password')} />
        </div>
      </AccountOptionBox>
      <VideoText htmlFor='' labelText={t('Video Annotations')} />
      <SwitchBox maxWidth={i18n.language === 'ja' ? '126px' : '163px'}>
        <SwitchText htmlFor='' labelText={`${boundingBoxes ? t('Enabled') : t('Disabled')}`} />
        <Switch
          checked={boundingBoxes}
          leftTheme='off'
          onChangeCallback={onCheckboxChange}
          rightTheme='on'
          state='default'
        />
      </SwitchBox>
      <TextForVideo>
        {t('Only for streams with this feature. Other streams will display as normal.')}
      </TextForVideo>
      <Border />
      <LogOut onClick={() => { sessionStorage.setItem('isGuestLogout', 'true'); AuthService.logoutUser(); }}>
        <PasswordReset htmlFor='logout' labelText={t('Logout')} />
      </LogOut>
    </>
  );
};

export default PasswordModal;