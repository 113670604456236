import LineViewer from 'components/LineViewer';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import { ButtonWithIcon, Content, Icon, Label, PageHeader, Spinner, Tab, TabContent, TabList, Tabs, useModal, useNotification, usePoll } from 'scorer-ui-kit';
import { getSingleCameraDetails } from '../services/apiConfig';
import { SpinnerContainer } from 'style';
import styled from 'styled-components';
import { Divider } from 'Styles';
import CameraDetailsFiles from './CameraDetailsFiles';
import { IPointSet } from 'scorer-ui-kit/dist/LineUI';
import TokenService from 'services/tokenService';
import { AlertIcon, CAMERA_DETAILS_STATUS_GET_INTERVAL, STATUS_ALERT_DETAILS, USB_STATUS_ALERT_DETAILS } from '../constants';
import { IAlertDetails } from 'interface';
import { getStatusCategory, getStatusColor, getTimeSinceFromSeconds } from 'utils';

const Header = styled.div`
  width: 940px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  line-height: 1.2;
  > div:first-child > div > h1 {
    max-width: 650px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
  @media only screen and (max-width: 1400px) {
    width: 825px !important;
    > div:first-child > div > h1 {
      max-width: 600px;
    }
  }
`;

const StyledDivider = styled(Divider)`
  max-width: 1010px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px 0;
  & > button {
    width: 100%;
    background: #e4edf4;
    &:hover:enabled {
      background: #d4e4f0;
    }
  }
  margin-top: -16px;
`;

const DetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  & tr, td {
    border: 1px solid #eee;
  }
  & > tbody > tr > td {
    height: 100px;
  }
  & > tbody > tr > td:first-child {
    vertical-align: middle;
    text-align: center;
  }
  & > tbody > tr > td:last-child {
    width: 83%;
    padding: 12px 5px 25px 30px;
  }
`;

const Heading = styled(Label)`
  margin-bottom: 10px;
  & > span {
    font-size: 16px;
    color: #5a6269;
  }
`;

const ReadMoreButton = styled.span`
  color: #4699d4;
  margin-left: 5px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

const NotesModalHeading = styled.div`
  margin-bottom: 31px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 20px;
  font-weight: 500;
  color: #727980;
`;

const NotesContainer = styled.div`
  width: 100%;
  max-height: 276px;
  font-size: 14px;
  overflow-y: auto;
  overflow-wrap: anywhere;
  white-space: break-spaces;
`;

const NotesModalContainer = styled.div`
  width: 100%;
  height: 418px;
  padding: 39px 40px;
`;

const DescriptionDiv = styled.div<IDescriptionDiv>`
  font-size: 12px;
  position: relative;
  overflow-wrap: anywhere;
  white-space: break-spaces;
  ${({width}) => width && `width: ${width};`}
  & > label {
    margin-bottom: 0;
    & > span {
      margin-bottom: 0;
      color: #5a6269;
    }
  }
  
  ${({ isStatus, statusColor }) => isStatus && `
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 100%;
      background: ${statusColor};
    }
  `}
`;

const DescriptionDivContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0 18px;
`;

const AreaContainer = styled.span`
  height: 15px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
  cursor: pointer;
  	&:hover {
      border-bottom: 1px solid #767676;;
      box-sizing: border-box;
      width: fit-content;
    }
`;

const CameraDetailsLeftPanel = styled.div`
  width: 382px;
`;

const ZoomButtonContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  & > div {
    display: flex;
  }
  :hover {
    color: #5aaeea;
    cursor: pointer;
    > div > svg > g {
      stroke: #5aaeea;
    }
  }
  position: absolute;
  right: 0;
  bottom: 10px;
`;
const ErrorBox = styled.div<{bgColor: string}>`
  padding: 10px 13px;
  margin-bottom: 14px;
  width: 100%;
  height: max-content;
  min-height: 46px;
  display: flex;
  align-items: center;
  gap: 0 10px;
  background: ${({bgColor}) => bgColor};
  > div {
    display: flex;
  }
`;

const ErrorContainer = styled.div`
  flex-direction: column;
  gap: 0 3px;
  color: #fff;
`;

const ErrorHeading = styled.div`
  font-size: 12px;
`;

const ErrorDescription = styled.div`
  font-size: 10px;
`;

const CameraDetailsRightPanel = styled.div`
  width: 540px;
  max-height: 450px;
  position: relative;
  img {
    border-radius: 3px;
  }
  @media screen and (max-width: 1400px) {
    width: 420px;
    > div:first-child {
      width: 420px;
    }
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const OverviewContainer = styled.div`
  width: 940px;
  margin-top: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    width: 100%;
  }
`;

const MainContent = styled.div`
  margin-top: 10px;
  @media only screen and (max-width: 1400px) {
    width: 825px !important;
  }
`;

interface IParams {
  streamName: string;
}

interface IDescriptionDiv {
  isStatus?: boolean;
  statusColor?: string;
  width?: string;
}

interface ILine {
  name: string,
  idx: number,
  points: {
    end_x: number,
    end_y: number,
    start_y: number,
    start_x: number,
  }
}

interface ILineConfiguration {
  lines: ILine[],
  analysis_enabled: boolean
}

interface IUsbDeviceStatus {
  status_code: number
}

interface ICameraDetails {
  camera_name: string,
  stream_name: string,
  notes: string,
  line_configuration: ILineConfiguration[],
  usb_device_status?: IUsbDeviceStatus,
  status?: {
    status_code: number,
    status_category: 'Running' | 'Error' | 'Warning' | 'Disabled'
  },
  latest_activity_time?: number
}

interface ICameraDetailsStatus {
  status_code: number,
  status_category: 'Running' | 'Error' | 'Warning' | 'Disabled'
}

const initialCameraDetails: ICameraDetails = {
  camera_name: 'Camera Name',
  stream_name: '',
  notes: '-',
  line_configuration: [],
};

export interface IDeviceDetailsParam{
  selectedTab: string | null
}

const CameraDetails: React.FC = () => {
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const history = useHistory();
  const { goBack } = useHistory();
  const { streamName } = useParams<IParams>();
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);
  const [cameraDetails, setCameraDetails] = useState<ICameraDetails>(initialCameraDetails);
  const [cameraDetailsStatus, setCameraDetailsStatus] = useState<ICameraDetailsStatus | undefined>(undefined);
  const [usbDeviceStatus, setUsbDeviceStatus] = useState<IUsbDeviceStatus | undefined>(undefined);
  const [cameraAnalysisStatus, setCameraAnalysisStatus] = useState<string>('');
  const [isMediaAvailable, setIsMediaAvailable] = useState<boolean>(false);
  const [linesData, setLinesData] = useState<IPointSet[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>('overview');
  const params = useLocation().search;
  const [ historyParams ] = useState<string>(params);
  const { sendNotification } = useNotification();
  const sendNotificationRef = useRef(sendNotification);

  const userName = TokenService.getUser();
  const { createModal, setModalOpen, isModalOpen } = useModal();
  const [isStopFrame, setIsStopFrame] = useState(false);
  const [boundingBoxes, setBoundingBoxes] = useState(JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string));
  const [modalState, setModalState] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);

  useEffect(() => {
    let state: IPointSet[] = [];
    const { line_configuration, status, usb_device_status } = cameraDetails;
    if (line_configuration[0]) {
      const { lines, analysis_enabled } = line_configuration[0];
      if (lines && lines.length > 0) {
        state = lines.map((item) => {
          return ({
            name: item.name,
            points: [
              {
                x: item.points.start_x,
                y: item.points.start_y
              },
              {
                x: item.points.end_x,
                y: item.points.end_y
              }
            ],
            readOnly: true,
            styling: 'primary'
          });
        });
      }
      
      if (analysis_enabled) {
        if(usb_device_status?.status_code as number | undefined === 30700) {   //When camera is removed
          setCameraAnalysisStatus('Inactive');
        }else if (lines.length > 0) {
          setCameraAnalysisStatus('Active');
        }else {
          setCameraAnalysisStatus('Not Configured');
        }
      } else {
        setCameraAnalysisStatus('Inactive');
      }
    }
    if (status) {
      setCameraDetailsStatus(status);
    }
    if (usb_device_status) {
      setUsbDeviceStatus(usb_device_status);
    }
    setLinesData(state);
    return () => {};
  }, [cameraDetails]);

  const getDetails = useCallback(async () => {
    setIsDataFetched(false);
    try {
      const res = await getSingleCameraDetails(streamName);
      setCameraDetails(res.data.data);
      setIsDataFetched(true);
    } catch (error) {
      setIsDataFetched(true);
      sendNotificationRef.current({ 
        type: 'error',
        message: tRef.current('Failed to load camera details.')
      });
      console.error(error);
    }
  }, [streamName]);

  const updateDetails = useCallback(async () => {
    try {
      const res = await getSingleCameraDetails(streamName);
      if(res?.data?.data?.status?.status_code === 30001){
        setTimeout(()=>{
          updateDetails();
        }, 3000);
      }
      setCameraDetails(res.data.data);
      setIsDataFetched(true);
    } catch (error) {
      setIsDataFetched(true);
      console.error(error);
    }
  }, [streamName]);

  useEffect(() => {
    getDetails();
    return () => {};
  }, [getDetails]);
  
  usePoll(async () => {
    updateDetails();
  }, CAMERA_DETAILS_STATUS_GET_INTERVAL * 1000);

  const omMediaLoaded = useCallback((isMediaLoaded: boolean) => {
    setIsMediaAvailable(isMediaLoaded);
  }, []);

  useEffect(()=>{
    if(isModalOpen){
      setIsStopFrame(true);
    } else{
      setIsStopFrame(false);
    }
    return () => {};
  }, [isModalOpen]);

  const onZoom = useCallback(() => {
    setModalState(true);
    setShowImage(true);
  }, []);

  // Notes Modal
  const getNotesModal = useCallback(() => {
    return (
      <NotesModalContainer>
        <NotesModalHeading>{t('Notes')}</NotesModalHeading>
        <NotesContainer>{cameraDetails.notes}</NotesContainer>
      </NotesModalContainer>
    );
  }, [cameraDetails, t]);

  const handleReadMore = useCallback(() => {
    setModalOpen(true);
    const notesModal: ReactElement = getNotesModal();

    createModal({
      isCloseEnable: true,
      width: '580px',
      padding: false,
      closeText: t('CLOSE'),
      customComponent: notesModal,
    });
  }, [createModal, setModalOpen, getNotesModal, t]);

  const getErrorDetails = useCallback((): ReactElement | null => {
    if (cameraDetailsStatus && cameraDetailsStatus.status_code && cameraDetailsStatus.status_category) {
      let Alert: IAlertDetails = STATUS_ALERT_DETAILS.Unknown;
      const { status_code, status_category } = cameraDetailsStatus;
      if (getStatusCategory(status_code) === 'Ok') {
        return null;
      } else {
        Alert = STATUS_ALERT_DETAILS[getStatusCategory(status_code)];
        return (
          <ErrorBox bgColor={getStatusColor(status_category)}>
            <Icon size={24} icon={AlertIcon[Alert.type]} color='inverse' />
            <ErrorContainer>
              <ErrorHeading>{t(Alert.title)}</ErrorHeading>
              <ErrorDescription>{t(Alert.message)}</ErrorDescription>
            </ErrorContainer>
          </ErrorBox>
        );
      }
    }
    return null;
  }, [cameraDetailsStatus, t]);

  const getUsbWarning = useCallback((): ReactElement | null => {
    if (usbDeviceStatus) {
      const { status_code } = usbDeviceStatus;
      if (status_code) {
        if (getStatusCategory(status_code, 'USB') === 'Ok') {
          return null;
        }
        const Alert: IAlertDetails = USB_STATUS_ALERT_DETAILS[getStatusCategory(status_code, 'USB')];
        return (
          <ErrorBox bgColor={usbDeviceStatus?.status_code === 30700 ? getStatusColor('Error') : getStatusColor('Warning')}>
            <Icon size={24} icon={AlertIcon[Alert.type]} color='inverse' />
            <ErrorContainer>
              <ErrorHeading>{t(Alert.title)}</ErrorHeading>
              <ErrorDescription>{Alert.message.includes('{X}') ? t(Alert.message).replace('{X}', ':') : `${t(Alert?.message)}`}</ErrorDescription>
            </ErrorContainer>
          </ErrorBox>
        );
      } else {
        return null;
      }
    }
    return null;
  }, [usbDeviceStatus, t]);

  const onSelectTab = useCallback((tabName: string) => {
    setSelectedTab(tabName);
    history.replace(window.location.pathname + '?selectedTab=' + tabName);
  }, [history]);
  
  const fetchHistoryParams = useCallback(() => {
    const urlString = new URLSearchParams(historyParams);
    const newParams: IDeviceDetailsParam = {
      selectedTab: null
    };
    newParams.selectedTab = urlString.get('selectedTab');
    if(newParams.selectedTab === null){
      setSelectedTab('overview');
    }
    if(newParams.selectedTab==='files'){
      setSelectedTab(newParams.selectedTab);
    }
  }, [ historyParams, setSelectedTab ]);

  useEffect(() => {
    fetchHistoryParams();
    return () => {};
  }, [ fetchHistoryParams ]);

  const gotoExportPage = useCallback(() => {
    const hParam = new URLSearchParams(window.location.search);
    if (hParam.get('fromDate') !== null && hParam.get('toDate') !== null){
      history.push(`/export?streamName=${streamName}&cameraName=${cameraDetails.camera_name}&fromDate=${hParam.get('fromDate')}&toDate=${hParam.get('toDate')}`);
    } else {
      history.push(`/export?streamName=${streamName}&cameraName=${cameraDetails.camera_name}`);
    }
    
  },[streamName, cameraDetails.camera_name, history]);

  const getLineBreak = (data:string) =>{
    const separateLines = data.split(/\r?\n|\r|\n/g);
    if (separateLines?.length > 5) {
      return true;
    }
  };

  useEffect(()=>{   //eslint-disable-line
    const { status, usb_device_status } = cameraDetails;
    if(usb_device_status || status){
      if(
        status?.status_code === 30001 || 
        status?.status_code === 30003 || 
        status?.status_code === 30002 || 
        status?.status_code === 20005 || 
        status?.status_code === 20002 || 
        status?.status_code === 20003 ||
        status?.status_code === 20004 ||
        status?.status_code === 20005 ||
        status?.status_code === 20006 ||
        status?.status_code === 20007 ||
        status?.status_code === 40000){
        setBoundingBoxes(false);
      }else {
        setBoundingBoxes(JSON.parse(localStorage.getItem(`checkoBox_Video_Annotation_${userName}`) as string));
      }
    }
    return () => {};
  });

  const goToPreviousPage = () => {
    goBack();
  };
  
  return (
    <>
      {isDataFetched ? (
        <Content>
          <Header>
            <PageHeaderComponent>
              <AreaContainer onClick={goToPreviousPage}>{t('Cameras')}</AreaContainer>
              <PageHeader
                title={cameraDetails.camera_name}
                icon='DevicesScorerCamera'
                updateDocTitle={false}
              />
            </PageHeaderComponent>  
            <ButtonContainer>
              {TokenService.getUserType() &&
                <>
                  <ButtonWithIcon icon='Edit' size='small' position='left' design='secondary' onClick={() => history.push(`/edit-camera?camera-name=${streamName}`)}>{t('Edit Camera')}</ButtonWithIcon>
                  <ButtonWithIcon disabled={usbDeviceStatus?.status_code === 30700 ? true : false} icon='Analyse' size='small' position='left' design='secondary' onClick={() => history.push(`/cameras/camera-configuration/${streamName}`)}>{t('Configure Analysis')}</ButtonWithIcon>
                </>}
              <ButtonWithIcon disabled={usbDeviceStatus?.status_code === 30700 ? true : false} icon='Download' size='small' position='left' design='secondary' onClick={() =>gotoExportPage()}>{t('Export Data')}</ButtonWithIcon>
            </ButtonContainer>
          </Header>
          <MainContent>
            <Tabs>
              <TabList defaultTabId={selectedTab}>
                <Tab tabFor='overview'><div onClick={() => { onSelectTab('overview'); }}>{t('Overview')}</div></Tab>
                <Tab tabFor='files'><div>{t('Files')}</div></Tab>
              </TabList>
              <StyledDivider />

              <TabContent tabId='overview'>
                <OverviewContainer>
                  <CameraDetailsLeftPanel>
                    {usbDeviceStatus?.status_code === 30700 ? '' : getErrorDetails()}
                    {getUsbWarning()}
                    <DetailsTable>
                      <tbody>
                        <tr>
                          <td><Icon icon='DevicesScorerCamera' size={20} color='dimmed' /></td>
                          <td>
                            <Heading htmlFor='cameraDetails' labelText={t('Camera Details')} />
                            <DescriptionDivContainer>
                              <DescriptionDiv
                                isStatus width='85px' statusColor={
                                  (!cameraDetailsStatus || !cameraDetailsStatus.status_category) ? getStatusColor('')
                                    : getStatusColor(cameraDetailsStatus.status_category)
                                }
                              >
                                <div>{t('Status')}</div>
                                <Label
                                  htmlFor='statusCamera' labelText={
                                    !cameraDetailsStatus || !cameraDetailsStatus.status_category ? '-'
                                      : t(cameraDetailsStatus.status_category)
                                  }
                                />
                              </DescriptionDiv>
                              <DescriptionDiv isStatus={false}>
                                <div>{t('Latest Activity')}</div>
                                <Label
                                  htmlFor='statusLatest' labelText={
                                    !cameraDetails.latest_activity_time ? '-'
                                      : getTimeSinceFromSeconds(cameraDetails.latest_activity_time)
                                  }
                                />
                              </DescriptionDiv>
                            </DescriptionDivContainer>
                          </td>
                        </tr>
                        <tr>
                          <td><Icon icon='Analyse' size={20} color='dimmed' /></td>
                          <td>
                            <Heading htmlFor='cameraDetails' labelText={t('Video Analysis')} />
                            <DescriptionDiv isStatus statusColor={cameraAnalysisStatus === 'Active' ? '#84df9e' : '#96acbc'}>
                              <div>{t('Status')}</div>
                              <Label htmlFor='statusAnalsis' labelText={!cameraAnalysisStatus ? '-' : t(cameraAnalysisStatus)} />
                            </DescriptionDiv>
                          </td>
                        </tr>
                        <tr>
                          {cameraDetails.notes !== '' ?
                            <>
                              <td><Icon icon='Edit' size={20} color='dimmed' /></td>
                              <td>
                                <Heading htmlFor='notes' labelText={t('Notes')} />
                                <DescriptionDiv>
                                  <div>
                                    {`${(getLineBreak(cameraDetails?.notes) ? cameraDetails.notes.slice(0, 7) + '...' : (cameraDetails.notes.length > 120 ? cameraDetails.notes.slice(0, 120) + '...'  : cameraDetails.notes))}`}

                                    {
                                      (cameraDetails.notes.length > 120 || (getLineBreak(cameraDetails?.notes))) && <ReadMoreButton onClick={handleReadMore}>{t('[Read More]')}</ReadMoreButton>
                                    }
                                  </div>
                                </DescriptionDiv>
                              </td>
                            </> : null}
                        </tr>
                      </tbody>
                    </DetailsTable>
                  </CameraDetailsLeftPanel>
                  <CameraDetailsRightPanel>
                    <LineViewer
                      streamName={streamName}
                      height='unset'
                      onMediaLoadedCallback={omMediaLoaded}
                      linesData={linesData}
                      isModal={modalState}
                      onImageClick={onZoom}
                      boundingBoxes={boundingBoxes}
                      isStopFrame={isStopFrame}
                      showImage={showImage}
                      setShowImage={setShowImage}
                    />
                    {
                      isMediaAvailable &&
                        <ZoomButtonContainer onClick={onZoom}>
                          <Icon icon='Search' size={14} color='dimmed' />
                          <div>{t('Zoom')}</div>
                        </ZoomButtonContainer>
                    }
                  </CameraDetailsRightPanel>
                </OverviewContainer>
              </TabContent>
              <TabContent tabId='files'>
                <CameraDetailsFiles streamName={streamName} selectedTabCallback={setSelectedTab} selectedTab={selectedTab} cameraName={cameraDetails.camera_name} />
              </TabContent>
            </Tabs>
          </MainContent>
        </Content>
      ) : (
        <SpinnerContainer>
          <Spinner size='large' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerContainer>
      )}
    </>
  );
};

export default CameraDetails;