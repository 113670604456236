import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonWithLoading, Icon, INotificationProps, SmallInput, useModal } from 'scorer-ui-kit';
import { ILineResponse, IPoints, ITrafficCounterData } from 'interface';
import styled from 'styled-components';

const ModalImportHeader = styled.div`
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const ImportInput = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
  gap: 0 15px;
`;

const CancelButton = styled(Button)`
  background: #e4edf4;
  :hover:enabled {
    background: #d4e4f0;
  }
`;

const ImportButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 15px;
`;

const ImportExportInput = styled(SmallInput)`
  width: 100%;
  & > label {
    width: 100%;
    margin-bottom: 0;
    & > span {
      margin-bottom: 0;
    }
  }
`;

const CloseButtonContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: -32.5px;
  letter-spacing: 0.3px;
  color: #a6a6a6;
  right: 0;
  display: flex;
  gap: 0 12px;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;

const CloseIcon = styled.div`
  & > div {
    display: flex;
  }
`;

const ModalContainer = styled.div`
  padding: 20px;
  min-width: 400px;
  min-height: 160px;
  position: relative;
`;

interface IProps {
  onImportCallback: (data: ITrafficCounterData) => void;
  sendNotification: (newNotification: INotificationProps) => void;
}

type TraficCounterRes = Omit<ITrafficCounterData, 'stream_type' | 'camera_name'>;
type TraficCounterKeys = keyof TraficCounterRes;
type LineKeys = keyof ILineResponse;
type PointsKeys = keyof IPoints;
type CheckTypeValue = number | string | boolean | ILineResponse[] | IPoints | undefined;

const checkType = (key: string, value: CheckTypeValue): boolean => {

  switch (key) {
  case 'analysis_enabled':
    return typeof value === 'boolean';
  case 'lines':
    return Array.isArray(value);
  case 'direction_type':
    return (typeof value === 'string' && ['Automatic', 'Manual'].includes(value));
  case 'name':
  case 'fwd_direction_label':
  case 'back_direction_label':
    return typeof value === 'string';
  case 'periodic_snapshot_interval':
  case 'idx':
  case 'start_x':
  case 'end_x':
  case 'end_y':
  case 'start_y':
    return typeof value === 'number';
  case 'points':
    return true;
  default:
    return false;
  }
};

const ImportSettingsModal: React.FC<IProps> = ({ onImportCallback, sendNotification }) => {

  const [selectedFile, setSelectedFile] = useState<FileList | undefined>();
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation(['CommonDict']);
  const { setModalOpen } = useModal();

  const onBrowse = useCallback(() => {
    if (fileInput.current !== null)
      fileInput.current.click();
  }, []);

  const isValidImportFile = useCallback((jsonData) => {
    let isLinesValid = false;
    const validTraficCounterKeys: TraficCounterKeys[] = ['analysis_enabled', 'lines'];
    const validLineKeys: LineKeys[] = ['idx', 'direction_type', 'fwd_direction_label', 'back_direction_label',
      'name', 'points'];
    const validPointsKeys: PointsKeys[] = ['start_x', 'end_x', 'start_y', 'end_y'];


    try {
      let resKeys: TraficCounterKeys[] = [], lineKeys: LineKeys[] = [], pointsKeys: PointsKeys[] = [];
      const resObj: TraficCounterRes = JSON.parse(jsonData);

      resKeys = Object.keys(resObj) as Array<keyof typeof resObj>;

      if (
        resKeys.every(key => validTraficCounterKeys.includes(key)) &&
        validTraficCounterKeys.every(key => (resKeys.includes(key) && checkType(key, resObj[key])))
      ) {

        if (resObj.lines.length === 0) return true;

        const isValidLineKeys = (lineKeys: LineKeys[]) => {
          return (
            lineKeys.every(key => validLineKeys.includes(key)) &&
            validLineKeys.every(key => lineKeys.includes(key))
          );
        };

        const isValidPointsKeys = (pointsKeys: PointsKeys[]) => {
          return (
            pointsKeys.every(key => validPointsKeys.includes(key)) &&
            validPointsKeys.every(key => pointsKeys.includes(key))
          );
        };

        const isValidLineKeyTypes = (lineKeys: LineKeys[], line: ILineResponse) => {
          return lineKeys.every(key => checkType(key, line[key]));
        };

        const isValidPointsKeyTypes = (pointsKeys: PointsKeys[], points: IPoints) => {
          return pointsKeys.every(key => checkType(key, points[key]));
        };

        for (const line of resObj.lines) {
          lineKeys = Object.keys(line) as Array<keyof typeof line>;
          lineKeys.splice(4, 1);
          delete line.large_vehicle_width;
          if (isValidLineKeys(lineKeys) && isValidLineKeyTypes(lineKeys, line)) {
            pointsKeys = Object.keys(line.points) as Array<keyof typeof line.points>;
            if (isValidPointsKeys(pointsKeys) && isValidPointsKeyTypes(pointsKeys, line.points)) {
              isLinesValid = true;
            } else {
              isLinesValid = false;
              break;
            }
          } else {
            isLinesValid = false;
            break;
          }
        }
        return isLinesValid;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }, []);

  const onChangeFile = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      setSelectedFile(event.target.files);
    } else {
      setSelectedFile(undefined);
    }
  }, []);

  const onImport = useCallback(() => {
    if (!selectedFile) {
      sendNotification(JSON.parse(JSON.stringify({
        type: 'error',
        message: t('Please select a file.')
      })));
      return;
    }
    setImportLoading(true);
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile[0], 'UTF-8');
    fileReader.onload = e => {
      if (e.target !== null && isValidImportFile(e.target.result)) {
        onImportCallback(JSON.parse(e.target.result as string));
      } else {
        sendNotification(JSON.parse(JSON.stringify({
          type: 'error',
          message: t('Invalid file.')
        })));
        setImportLoading(false);
      }
    };
  }, [selectedFile, t, isValidImportFile, sendNotification, onImportCallback]);

  return (
    <ModalContainer>
      <CloseButtonContainer onClick={() => setModalOpen(false)}>
        {t('CLOSE')}
        <CloseIcon>
          <Icon icon='CloseCompact' color='dimmed' size={16} />
        </CloseIcon>
      </CloseButtonContainer>
      <ModalImportHeader>{t('Please select JSON file')}</ModalImportHeader>
      <ImportInput>
        <input type='file' ref={fileInput} onChange={onChangeFile} accept='.json' style={{ display: 'none' }} />
        <ImportExportInput onClick={onBrowse} label='' value={selectedFile ? selectedFile[0].name : ''} placeholder={t('No files selected')} readOnly />
        <Button style={{ flexShrink: '0' }} onClick={onBrowse} size='small'>{t('Browse')}</Button>
      </ImportInput>
      <ImportButton>
        <CancelButton
          design='secondary'
          size='small'
          onClick={() => setModalOpen(false)}
        >
          {t('Cancel')}
        </CancelButton>
        <ButtonWithLoading loading={importLoading} onClick={onImport} design='primary' size='small'>
          {t('Import')}
        </ButtonWithLoading>
      </ImportButton>
    </ModalContainer>
  );
};

export default ImportSettingsModal;